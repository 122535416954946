import { queryOptions, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { useOutletContext } from "react-router-dom";
import { mapboxApi, patientApi } from "./ApiClient";
import { HOSPITAL_COORDINATES } from "../Utils/EnvUtils";
import { generatePatches, generateQueryString } from "./Helpers";
import { formatPostalCodeWithSpace } from "@/Utils/postalCodeUtils";
import type { IPatient, IPatientStatus, IRelative } from "@models/patients";
import {
  listAdmittedPatientSchema,
  listDischargedPatientSchema,
  listPatientSchema,
  listProspectPatientSchema,
  patientSchema,
  patientStatusSchema,
  patientStatusDictionary,
  wardsSchema,
} from "@models/patients";
import type { IAddress, IAddressWithCoordinates } from "@models/addresses";

export const patientsSchema = z.array(listPatientSchema);
export type IListPatient = z.infer<typeof listPatientSchema>;

const prospectPatientsSchema = z.array(listProspectPatientSchema);
export type IListProspectPatient = z.infer<typeof listProspectPatientSchema>;

const admittedPatientsSchema = z.array(listAdmittedPatientSchema);
export type IListAdmittedPatient = z.infer<typeof listAdmittedPatientSchema>;

const dischargedPatientsSchema = z.array(listDischargedPatientSchema);
export type IListDischargedPatient = z.infer<
  typeof listDischargedPatientSchema
>;

export const fetchPatients = async ({
  patientIds,
  statuses,
}: {
  patientIds?: string[];
  statuses?: IPatientStatus[];
}) => {
  const queryString = generateQueryString({ id: patientIds, status: statuses });
  const patientsResponse = await patientApi.get(`/patients${queryString}`);
  const parsedPatients = patientsSchema.parse(patientsResponse.data);
  return parsedPatients;
};

export const fetchProspectPatients = async ({
  patientIds,
}: {
  patientIds?: string[];
}) => {
  const queryString = generateQueryString({
    id: patientIds,
    status: patientStatusSchema.Values.prospect,
  });
  const patientsResponse = await patientApi.get(`/patients${queryString}`);
  const parsedPatients = prospectPatientsSchema.parse(patientsResponse.data);
  return parsedPatients;
};

export const fetchAdmittedPatients = async ({
  patientIds,
}: {
  patientIds?: string[];
}) => {
  const queryString = generateQueryString({
    id: patientIds,
    status: patientStatusSchema.Values.admitted,
  });
  const patientsResponse = await patientApi.get(`/patients${queryString}`);
  const parsedPatients = admittedPatientsSchema.parse(patientsResponse.data);
  return parsedPatients;
};

export const fetchDischargedPatients = async ({
  patientIds,
}: {
  patientIds?: string[];
}) => {
  const queryString = generateQueryString({
    id: patientIds,
    status: patientStatusSchema.Values.discharged,
  });
  const patientsResponse = await patientApi.get(`/patients${queryString}`);
  const parsedPatients = dischargedPatientsSchema.parse(patientsResponse.data);
  return parsedPatients;
};

export const patientKeys = {
  all: ["patients"] as const,
  lists: () => [...patientKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...patientKeys.lists(), { filters }] as const,
  detail: (id: string) => [...patientKeys.all, id, "details"] as const,
};

const wardsKeys = {
  all: ["wards"] as const,
  detail: (id: number) => [...wardsKeys.all, id, "details"] as const,
};

export const usePatients = ({
  statuses,
  sort = "name-asc",
}: {
  statuses: IPatientStatus[];
  sort?: "name-asc";
}) => {
  return useQuery({
    queryKey: patientKeys.list({ statuses, sort }),
    queryFn: async () =>
      (await fetchPatients({ statuses })).sort((a, b) => {
        switch (sort) {
          case "name-asc":
            return a.name.localeCompare(b.name);
          default:
            return 0;
        }
      }),
  });
};

export const useProspectPatients = ({
  sort = "name-asc",
}: {
  sort?: "name-asc";
}) => {
  return useQuery({
    queryKey: patientKeys.list({
      statuses: [patientStatusSchema.Values.prospect],
      sort,
    }),
    queryFn: async () =>
      (await fetchProspectPatients({})).sort((a, b) => {
        switch (sort) {
          case "name-asc":
            return a.name.localeCompare(b.name);
          default:
            return 0;
        }
      }),
  });
};

export const useAdmittedPatients = ({
  sort = "name-asc",
}: {
  sort?: "name-asc";
}) => {
  return useQuery({
    queryKey: patientKeys.list({
      statuses: [patientStatusSchema.Values.admitted],
      sort,
    }),
    queryFn: async () =>
      (await fetchAdmittedPatients({})).sort((a, b) => {
        switch (sort) {
          case "name-asc":
            return a.name.localeCompare(b.name);
          default:
            return 0;
        }
      }),
  });
};

export const useDischargedPatients = ({
  sort = "name-asc",
}: {
  sort?: "name-asc";
}) => {
  return useQuery({
    queryKey: patientKeys.list({
      statuses: [patientStatusSchema.Values.discharged],
      sort,
    }),
    queryFn: async () =>
      (await fetchDischargedPatients({})).sort((a, b) => {
        switch (sort) {
          case "name-asc":
            return a.name.localeCompare(b.name);
          default:
            return 0;
        }
      }),
  });
};

export async function fetchPatient(id: string) {
  const patientResponse = await patientApi.get(`/patients/${id}`);
  const parsedPatient = patientSchema.parse(patientResponse.data);
  return parsedPatient;
}

export const usePatient = (id: string) => {
  return useQuery({
    queryKey: patientKeys.detail(id),
    queryFn: () => fetchPatient(id),
  });
};

async function fetchPatientsWithUnhandledMeasurements() {
  const response = await patientApi.get(`/patients/unhandled-measurements`);
  const parsedPatientIds = z
    .array(
      z.object({
        id: z.string().uuid(),
        unhandledMeasurementsCount: z.number(),
      }),
    )
    .parse(response.data);
  return parsedPatientIds;
}

export const patientsWithUnhandledMeasurementsQueryOptions = queryOptions({
  queryKey: patientKeys.list({ hasUnhandledMeasurements: true }),
  queryFn: fetchPatientsWithUnhandledMeasurements,
  // This request can get unnecessarily noisy (~1 request per navigation).
  // Prefer to re-use data and refresh using SSE.
  staleTime: Infinity,
  // Fall back to polling every 5 minutes to ensure relatively fresh data independent of SSE.
  refetchInterval: 1000 * 60 * 5,
});

export async function fetchCoordinates(
  parameters:
    | (Pick<IAddress, "addressLine1" | "city" | "postalCode"> & {
        apiVersion: "5";
      })
    | (Pick<IAddress, "addressLine1" | "postalCode"> & { apiVersion: "6" }),
): Promise<{
  longitude: number;
  latitude: number;
}> {
  if (parameters.apiVersion === "5") {
    const { addressLine1, city, postalCode } = parameters;
    const hospitalLongitudeLatitude = `${HOSPITAL_COORDINATES.longitude},${HOSPITAL_COORDINATES.latitude}`;

    const formattedPostalCode = formatPostalCodeWithSpace(postalCode);

    const encodedURL = encodeURI(
      `/geocoding/v5/mapbox.places/${addressLine1} ${formattedPostalCode} ${city}.json?limit=1&proximity=${hospitalLongitudeLatitude}&types=address`,
    );
    const response = await mapboxApi.get(encodedURL);
    // Expected response as per: https://docs.mapbox.com/playground/geocoding/?search_text=Tomtebogatan%2018%20113%2038%20Stockholm&country=se&limit=1&proximity=ip&types=address
    const coordinates: [number, number] = response.data.features[0].center;
    return { longitude: coordinates[0], latitude: coordinates[1] };
  }

  const { addressLine1: address_line1, postalCode } = parameters;
  const postcode = formatPostalCodeWithSpace(postalCode);

  // Ignored since Mapbox often thinks that the 'place' is the municipality, not the city.
  // const place = city;

  // Ignored since 'proximity' is more dynamic.
  // ~ 50 km north, west, east, south of HOSPITAL_COORDINATES
  // const { minLon, minLat, maxLon, maxLat } = {
  //   minLon: HOSPITAL_COORDINATES.longitude - 1,
  //   minLat: HOSPITAL_COORDINATES.latitude - 0.5,
  //   maxLon: HOSPITAL_COORDINATES.longitude + 1,
  //   maxLat: HOSPITAL_COORDINATES.latitude + 0.5,
  // };
  // const bbox = `${minLon},${minLat},${maxLon},${maxLat}`;
  const country = "se";
  const language = "sv";
  const limit = 1;
  const proximity = `${HOSPITAL_COORDINATES.longitude},${HOSPITAL_COORDINATES.latitude}`;
  const types = "address";
  const queryString = generateQueryString({
    address_line1,
    postcode,
    // bbox,
    country,
    language,
    limit,
    // place,
    proximity,
    types,
  });

  // https://docs.mapbox.com/api/search/geocoding/#forward-geocoding-with-structured-input
  const forwardGeocodingURL = `/search/geocode/v6/forward${queryString}`;
  const response = await mapboxApi.get(forwardGeocodingURL);
  // Expected response as per: https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object
  const coordinates: [number, number] =
    response.data.features[0].geometry.coordinates;
  return { longitude: coordinates[0], latitude: coordinates[1] };
}

export function useParentRoutesPatient() {
  return useOutletContext<IPatient>();
}

export const dischargePatient = async (patientId: string) => {
  await patientApi.post(`/patients/${patientId}/discharge`);
};

export const readmitPatient = async (patientId: string) => {
  await patientApi.post(`/patients/${patientId}/readmit`);
};

export const declinePatient = async (patientId: string) => {
  await patientApi.post(`/patients/${patientId}/decline`);
};

export const updateAddress = async (
  patientId: string,
  address: IAddressWithCoordinates,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      address,
    }),
  );
};

export const updatePhoneNumber = async (
  patientId: string,
  phoneNumber: string,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      phoneNumber,
    }),
  );
};

export const updateInformation = async (
  patientId: string,
  information: string | null | undefined,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      information,
    }),
  );
};

export const updateName = async (patientId: string, name: string) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      name,
    }),
  );
};

export const updateWard = async (patientId: string, ward: number) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      ward,
    }),
  );
};

export const addRelative = async (patientId: string, relative: IRelative) => {
  await patientApi.post(`/patients/${patientId}/relatives`, {
    ...relative,
  });
};

export const removeRelative = async (patientId: string, relativeId: string) => {
  await patientApi.delete(`/patients/${patientId}/relatives/${relativeId}`);
};

export const updateHealthcareJourneyNumber = async (
  patientId: string,
  healthcareJourneyNumber: string | null | undefined,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      healthcareJourneyNumber,
    }),
  );
};

export const updateSafetyAlarmText = async (
  patientId: string,
  safetyAlarmText: string | null | undefined,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({ safetyAlarmText }),
  );
};

export const SMS_NOTIFICATION_MESSAGE_FROM_CHAT =
  "Hej! Du har ett nytt chattmeddelande från Medoma. Öppna din platta och gå till chatten för att läsa.";

export const notifyBySMS = async (patientId: string) => {
  await patientApi.post(`/patients/${patientId}/notify`, {
    message: SMS_NOTIFICATION_MESSAGE_FROM_CHAT,
  });
};

export const getPatientNameWithStatus = ({
  name,
  status,
}: Pick<IPatient, "name" | "status">): string => {
  if (status === patientStatusSchema.Values.admitted) {
    return name;
  }
  return `${name} (${patientStatusDictionary[
    status
  ].singular.sv.toLocaleLowerCase()})`;
};

export const getAllWards = async () => {
  const wardsResponse = await patientApi.get("/wards");
  return wardsSchema.parse(wardsResponse.data);
};

export const useWards = () => {
  return useQuery({
    queryKey: wardsKeys.all,
    queryFn: () => getAllWards(),
  });
};
