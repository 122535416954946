import {
  addJitActivityInVisit,
  addJustInTimeActivity,
  type INewJitActivityInVisit,
  type INewJustInTimeActivity,
} from "@/api/Activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { Loading } from "@components/Loading/Loading";
import TextArea from "@/components/TextArea/TextArea";
import CheckmarkIcon from "@components/icons/CheckmarkIcon";
import { deducedError } from "@/Utils/ErrorUtils";
import { generateRandomUUID } from "@/Utils/UniqueId";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useFeatureFlag } from "@/api/FeatureFlags";

interface IPostRegisterActivityFormData {
  description: string;
  title: string;
}

const PostRegisterActivity = ({
  onSuccess,
  routeId,
  visitId,
  patientId,
}: {
  onSuccess: () => void;
  routeId: string;
  visitId: string;
  patientId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<IPostRegisterActivityFormData>();

  const { data: isActivityGroupingEnabled } =
    useFeatureFlag("ActivityGrouping");

  const { mutate: addJitActivityMutation, isPending: isAdding } = useMutation({
    mutationFn: ({ newActivity }: { newActivity: INewJitActivityInVisit }) =>
      addJitActivityInVisit(newActivity),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: () => {
      reset();
      return onSuccess();
    },
  });

  //TODO: Remove this when ActivityGrouping is enabled.
  const { mutate: addLegacyActivityMutation, isPending: isAddingLegacy } =
    useMutation({
      mutationFn: ({
        newLegacyActivity,
      }: {
        newLegacyActivity: INewJustInTimeActivity;
      }) => addJustInTimeActivity(newLegacyActivity),
      onError: (error) => {
        setError("root.server", {
          message: deducedError(error),
        });
        // Reset `isDirty` to support only showing server error when the form is not changed.
        reset(getValues(), {
          keepErrors: true,
          keepIsSubmitted: true,
          keepTouched: true,
          keepIsValid: true,
          keepSubmitCount: true,
        });
      },
      onSuccess: () => {
        reset();
        return onSuccess();
      },
    });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        const newActivity: INewJitActivityInVisit = {
          activityId: generateRandomUUID(),
          title: formData.title,
          description: formData.description,
          visitId,
          routeId,
        };

        const newLegacyActivity: INewJustInTimeActivity = {
          activityId: generateRandomUUID(),
          title: formData.title,
          description: formData.description,
          visitId,
          routeId,
          patientId,
        };

        if (isActivityGroupingEnabled) {
          addJitActivityMutation({ newActivity });
        } else {
          addLegacyActivityMutation({ newLegacyActivity });
        }
      })}
    >
      {isAdding || isAddingLegacy ? (
        <Loading message="Efterregistrerar aktivitet" />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <InputField
            label="Namn"
            errorMessage={errors.title?.message}
            {...register(`title`, {
              required: {
                value: true,
                message: "Aktiviteten behöver ett namn.",
              },
            })}
          />
          <TextArea
            label="Beskrivning (valfri)"
            errorMessage={errors.description?.message}
            {...register(`description`)}
          />
          <FilledButton type="submit" width="fill-container">
            <CheckmarkIcon />
            Lägg till aktiviteten
          </FilledButton>
        </>
      )}
    </Form>
  );
};

export { PostRegisterActivity };
