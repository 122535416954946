import { useParams } from "react-router-dom";
import { patientKeys, removeRelative, usePatient } from "@/api/Patients";
import Address from "@/components/Address/Address";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import styles from "./Information.module.scss";
import type { IPlainButton } from "@components/Button/Button";
import { PlainButton } from "@components/Button/Button";
import { useState } from "react";
import { EditPhoneNumber } from "@/forms/EditPhoneNumber";
import { EditAddress } from "@/forms/EditAddress";
import { EditInformation } from "@/forms/EditInformation";
import { AddRelative } from "@/forms/AddRelative";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { EditHealthcareJourneyNumber } from "@/forms/EditHealthcareJourneyNumber";
import { EditSafetyAlarmText } from "@/forms/EditSafetyAlarm";
import PlusIcon from "@components/icons/PlusIcon";
import { z } from "zod";
import { Heading } from "@components/Heading/Heading";
import { EditName } from "@/forms/EditName";
import { dateName } from "@/Utils/DateUtils";
import { EditWard } from "@/forms/EditWard";
import { useFeatureFlag } from "@/api/FeatureFlags";
import * as Sentry from "@sentry/react";

const Information = () => {
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const [currentlyEditing, setCurrentlyEditing] = useState<
    | "address"
    | "phonenumber"
    | "healthcareJourneyNumber"
    | "information"
    | "add-relative"
    | "safety-alarm"
    | "name"
    | "ward"
    | null
  >(null);
  const { data: patient, isPending, isError, error } = usePatient(patientId);
  const { data: cardiologyEnabled } = useFeatureFlag("CardiologyWorkflow");

  const queryClient = useQueryClient();
  const { mutate: removeRelativeMutation, isPending: isRemovingRelative } =
    useMutation({
      mutationFn: ({
        relativeId,
        patientId,
      }: {
        relativeId: string;
        patientId: string;
      }) => removeRelative(patientId, relativeId),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att ta bort anhörig. ${deducedError(error)}`,
        );
      },
      onSuccess: (_, { patientId }) => {
        queryClient.invalidateQueries({
          queryKey: patientKeys.detail(patientId),
        });
      },
    });

  if (isPending) {
    return <Loading message="Laddar patientinformation" padding={24} />;
  }
  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} />;
  }

  const EditButton = ({
    children,
    onClick,
  }: Pick<IPlainButton, "children" | "onClick">) => (
    <PlainButton size="small" weight="light" onClick={onClick}>
      {children}
    </PlainButton>
  );

  const DateOf = () => {
    const { status } = patient;
    const [heading, content] =
      status === "prospect"
        ? ["Registreringsdatum", dateName(patient.registeredAt)]
        : status === "admitted"
          ? ["Inskrivningsdatum", dateName(patient.admittedAt)]
          : status === "discharged"
            ? ["Utskrivningsdatum", dateName(patient.dischargedAt)]
            : ["[Rubrik saknas]", "-"];

    return (
      <section className={styles.informationSection}>
        <Heading level="h2">{heading}</Heading>
        {content}
      </section>
    );
  };

  return (
    <div className={styles.information}>
      <DateOf />
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">Namn</Heading>
          {currentlyEditing !== "name" ? (
            <EditButton onClick={() => setCurrentlyEditing("name")}>
              Ändra
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              Avbryt
            </EditButton>
          )}
        </div>
        {currentlyEditing === "name" ? (
          <EditName
            currentName={patient.name}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          patient.name
        )}
      </section>

      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">Adress</Heading>
          {currentlyEditing !== "address" ? (
            <EditButton onClick={() => setCurrentlyEditing("address")}>
              Ändra
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              Avbryt
            </EditButton>
          )}
        </div>
        {currentlyEditing === "address" ? (
          <EditAddress
            currentAddress={patient.address}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          <>
            <Address address={patient.address} showPostalCode />
            {patient.address.additionalInformation ? (
              <ul>
                {patient.address.additionalInformation
                  .split("\n")
                  .map((item, index) => (
                    <li key={item + index}>{item}</li>
                  ))}
              </ul>
            ) : undefined}
          </>
        )}
      </section>

      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">Telefonnummer</Heading>
          {currentlyEditing !== "phonenumber" ? (
            <EditButton onClick={() => setCurrentlyEditing("phonenumber")}>
              Ändra
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              Avbryt
            </EditButton>
          )}
        </div>
        {currentlyEditing === "phonenumber" ? (
          <EditPhoneNumber
            currentPhoneNumber={patient.phoneNumber}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          patient.phoneNumber
        )}
      </section>

      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">Allmän information</Heading>
          {currentlyEditing !== "information" ? (
            <EditButton onClick={() => setCurrentlyEditing("information")}>
              Ändra
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              Avbryt
            </EditButton>
          )}
        </div>
        {currentlyEditing === "information" ? (
          <EditInformation
            currentInformation={patient.information}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.information ? (
          <ul>
            {patient.information.split("\n").map((item, index) => (
              <li key={item + index}>{item}</li>
            ))}
          </ul>
        ) : (
          "-"
        )}
      </section>

      {cardiologyEnabled ? (
        <section className={styles.informationSection}>
          <div className={styles.editableSection}>
            <Heading level="h2">Avdelning</Heading>
            {currentlyEditing !== "ward" ? (
              <EditButton onClick={() => setCurrentlyEditing("ward")}>
                Ändra
              </EditButton>
            ) : (
              <EditButton onClick={() => setCurrentlyEditing(null)}>
                Avbryt
              </EditButton>
            )}
          </div>
          {currentlyEditing === "ward" ? (
            <EditWard
              currentWard={patient.ward}
              onSuccess={() => setCurrentlyEditing(null)}
              patientId={patient.id}
            />
          ) : (
            patient.ward.displayName
          )}
        </section>
      ) : null}

      <section className={styles.informationSection}>
        <Heading level="h2">Anhöriga</Heading>
        {patient.relatives.length >= 1 ? (
          <ul className={styles.relativeList}>
            {patient.relatives.map(
              ({ name, phoneNumber, relation, isLivingTogether }) => (
                <li key={name}>
                  <p>{`${name} (${relation.toLocaleLowerCase()}) ${
                    isLivingTogether ? `bor med ${patient.name} och` : ""
                  } nås på ${phoneNumber}.`}</p>
                </li>
              ),
            )}
          </ul>
        ) : (
          "-"
        )}
        {currentlyEditing !== "add-relative" ? (
          <>
            <div className={styles.linedUpButtons}>
              <PlainButton onClick={() => setCurrentlyEditing("add-relative")}>
                <PlusIcon />
                Lägg till anhörig
              </PlainButton>
              {patient.relatives.length >= 1 ? (
                <DropdownMenu
                  trigger={{
                    text: "Ta bort en anhörig",
                    weight: "regular",
                  }}
                >
                  {patient.relatives.map((relative) => {
                    return (
                      <DropdownMenu.Item
                        action={() =>
                          removeRelativeMutation({
                            patientId: patient.id,
                            relativeId: relative.id,
                          })
                        }
                        content={`Ta bort ${relative.name}`}
                        disabled={isRemovingRelative}
                        key={relative.id}
                      />
                    );
                  })}
                </DropdownMenu>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <EditButton onClick={() => setCurrentlyEditing(null)}>
            Avbryt
          </EditButton>
        )}
        {currentlyEditing === "add-relative" ? (
          <AddRelative
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : undefined}
      </section>
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">Sjukresenummer</Heading>
          {currentlyEditing !== "healthcareJourneyNumber" ? (
            <EditButton
              onClick={() => setCurrentlyEditing("healthcareJourneyNumber")}
            >
              Ändra
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              Avbryt
            </EditButton>
          )}
        </div>

        {currentlyEditing === "healthcareJourneyNumber" ? (
          <EditHealthcareJourneyNumber
            currentHealthcareJourneyNumber={patient.healthcareJourneyNumber}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.healthcareJourneyNumber ? (
          <p>{patient.healthcareJourneyNumber}</p>
        ) : (
          "-"
        )}
      </section>
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">Trygghetslarm</Heading>
          {currentlyEditing !== "safety-alarm" ? (
            <EditButton onClick={() => setCurrentlyEditing("safety-alarm")}>
              Ändra
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              Avbryt
            </EditButton>
          )}
        </div>
        {currentlyEditing === "safety-alarm" ? (
          <EditSafetyAlarmText
            currentSafetyAlarmText={patient.safetyAlarmText}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.safetyAlarmText ? (
          <p>{patient.safetyAlarmText}</p>
        ) : (
          "-"
        )}
      </section>
    </div>
  );
};

export default Information;
