import type { IActivityCategory } from "@models/activities";
import styles from "./CategoryIcon.module.scss";
import clsx from "clsx";
import HouseIcon from "@components/icons/HouseIcon";
import VideoCameraIcon from "@components/icons/VideoCameraIcon";
import AvatarIcon from "@components/icons/AvatarIcon";
import MedicalCircleIcon from "@components/icons/MedicalCircleIcon";
import HealthParametersIcon from "@components/icons/HealthParametersIcon";
import BulletListIcon from "@components/icons/BulletListIcon";

type ICategoryIcon = {
  category: IActivityCategory | "MultipleCategories";
  size?: "small" | "medium";
};

export const CategoryIcon = ({ category, size = "medium" }: ICategoryIcon) => {
  const renderIcon = () => {
    switch (category) {
      case "HomeVisit":
        return <HouseIcon />;
      case "VideoCall":
        return <VideoCameraIcon />;
      case "PatientTask":
        return <AvatarIcon />;
      case "AdminTask":
        return <MedicalCircleIcon />;
      case "PatientMeasurementTask":
        return <HealthParametersIcon />;
      case "MultipleCategories":
        return <BulletListIcon />;
      default:
        return null;
    }
  };

  return (
    <div
      className={clsx([
        styles.container,
        styles[`category-${category}`],
        styles[`size-${size}`],
      ])}
    >
      {renderIcon()}
    </div>
  );
};
