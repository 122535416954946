import { OutlinedButton } from "@components/Button/Button";
import { useParams } from "react-router-dom";
import { z } from "zod";
import styles from "./Communication.module.scss";
import ChatComponent from "./ChatComponent";
import { Heading } from "@components/Heading/Heading";
import {
  notifyBySMS,
  SMS_NOTIFICATION_MESSAGE_FROM_CHAT,
  usePatient,
} from "@/api/Patients";
import { useMutation } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import type { IPatient } from "@models/patients";
import { activeStatuses, patientStatusDictionary } from "@models/patients";
import { Text } from "@components/Text/Text";
import { useFeatureFlag } from "@/api/FeatureFlags";

const hasPhoneNumber = ({ phoneNumber }: Pick<IPatient, "phoneNumber">) =>
  Boolean(phoneNumber);
const isProspectOrAdmitted = ({ status }: Pick<IPatient, "status">) =>
  activeStatuses.some((s) => s === status);
const canSendSms = ({
  phoneNumber,
  status,
}: Pick<IPatient, "phoneNumber" | "status">) =>
  hasPhoneNumber({ phoneNumber }) && isProspectOrAdmitted({ status });

const smsButtonExplanationId = "sms-button-explanation";
const SmsButtonExplanation = ({
  patient,
}: {
  patient: Pick<IPatient, "name" | "phoneNumber" | "status">;
}) => {
  if (!hasPhoneNumber(patient)) {
    return (
      <Text element="p" id={smsButtonExplanationId}>
        Det går inte att skicka SMS eftersom patienten inte har något
        telefonnummer registrerat.
      </Text>
    );
  }

  if (!isProspectOrAdmitted(patient)) {
    return (
      <Text element="p" id={smsButtonExplanationId}>
        SMS kan endast skickas till patienter som är{" "}
        {patientStatusDictionary["prospect"].plural.sv.toLocaleLowerCase()}{" "}
        eller{" "}
        {patientStatusDictionary["admitted"].plural.sv.toLocaleLowerCase()}.
      </Text>
    );
  }

  return (
    <Text element="p" id={smsButtonExplanationId}>
      Vid behov, skicka ett SMS till {patient.name ?? "patienten"}. Meddelandet
      uppmanar mottagaren att öppna och läsa chatten.
    </Text>
  );
};

export const Communication = () => {
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const { data: patient } = usePatient(patientId);

  const { data: newVideoSolution } = useFeatureFlag("NewVideoSolution");
  const { data: acsVideoEnabled } = useFeatureFlag("ACSVideo");

  const { mutate: sendChatNotificationSMS, isPending: isSendingSMS } =
    useMutation({
      mutationFn: () => {
        return notifyBySMS(patientId);
      },
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att skicka SMS. ${deducedError(error)}`,
        );
      },
    });

  if (!patient) {
    return null;
  }

  const handleSendChatNotificationSMSClick = () => {
    if (
      window.confirm(
        `Följande SMS skickas till ${patient.name ?? "patienten"} (${
          patient.phoneNumber
        }):\n\n"${SMS_NOTIFICATION_MESSAGE_FROM_CHAT}"\n\nVill du skicka det?`,
      )
    ) {
      sendChatNotificationSMS();
    } else return;
  };

  return (
    <div className={styles.container}>
      <div className={styles.chatContainer}>
        <ChatComponent />
      </div>
      <section className={styles.communicationTools}>
        <article>
          <Heading level="h2">SMS-notis</Heading>
          <SmsButtonExplanation patient={patient} />
          <OutlinedButton
            aria-describedby={smsButtonExplanationId}
            disabled={isSendingSMS || !canSendSms(patient)}
            onClick={handleSendChatNotificationSMSClick}
          >
            Skicka SMS
          </OutlinedButton>
        </article>
        <article>
          <Heading level="h2">Videosamtal</Heading>
          <Text element="p" id="video-meeting-explanation">
            Videosamtalet öppnas i en ny flik i din webbläsare. Du kan växla
            mellan webbläsarens flikar som du vill.
          </Text>
          <OutlinedButton
            aria-describedby="video-meeting-explanation"
            onClick={() =>
              window.open(`/video/${patientId}?video-service=jitsi`, "_blank")
            }
          >
            {newVideoSolution || acsVideoEnabled
              ? "Starta videosamtal (Jitsi)"
              : "Starta videosamtal"}
          </OutlinedButton>
          {newVideoSolution ? (
            <OutlinedButton
              aria-describedby="video-meeting-explanation"
              onClick={() =>
                window.open(
                  `/video/${patientId}?video-service=compodium`,
                  "_blank",
                )
              }
            >
              Starta videosamtal (Compodium)
            </OutlinedButton>
          ) : null}
          {acsVideoEnabled ? (
            <OutlinedButton
              aria-describedby="video-meeting-explanation"
              onClick={() =>
                window.open(`/video/${patientId}?video-service=acs`, "_blank")
              }
            >
              Starta videosamtal (ACS)
            </OutlinedButton>
          ) : null}
        </article>
      </section>
    </div>
  );
};
