import styles from "./Details.module.scss";
import { Text } from "@components/Text/Text";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import type { IActivityOccurrence } from "@models/activities";
import {
  getActivityOccurrenceStatusTimestamp,
  timeOfDayDictionary,
} from "@models/activities";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import { type IVisit, visitStatusSchema } from "@models/visits";
import { calculateVisitTimes, getVisitStatusTimestamp } from "@models/visits";
import { ActivityGroupTag } from "./ActivityGroupTag/ActivityGroupTag";
import type { IHomeVisitActivityOccurrenceWithoutPatientAndAssignees } from "@models/activities";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { StatusTagWithDropdown } from "@/components/StatusTagWithDropdown/StatusTagWithDropdown";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { unassignGroupFromRoute } from "@/api/Routes";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import {
  activityOccurrenceAndGroupKeys,
  allocateShiftsToActivityOccurrence,
  allocateShiftsToVisit,
  deallocateShiftsFromActivityOccurrence,
  deallocateShiftsFromVisit,
} from "@/api/Activities";
import { shiftKeys } from "@/api/Shifts";
import { QuickActivityInDetails } from "@/pages/commandcenter/Shifts/TimelineTile/QuickActivityInDetails";
import { AssignedShiftChip } from "@/components/Chips/AssignedShiftChip";
import { startOfDay, startOfToday } from "date-fns";
import { ShiftDropdownMenu } from "@/pages/commandcenter/Activities/ShiftDropdownMenu";
import { getUnfulfilledRequirements } from "@/pages/commandcenter/Activities/WorkBlocks/helpers";

const ActivityOccurrence = ({
  activityOccurrence,
}: {
  activityOccurrence: IHomeVisitActivityOccurrenceWithoutPatientAndAssignees;
}) => {
  const { status } = activityOccurrence;

  return (
    <div className={styles.occurrenceListItem}>
      {status === "finished" || status === "expired" ? (
        <StatusTag
          status={status}
          timestamp={getActivityOccurrenceStatusTimestamp(activityOccurrence)}
          size="tiny"
        />
      ) : null}
      <ActivityTitle activityOccurrence={activityOccurrence} />
    </div>
  );
};

type IDetails = {
  visit: IVisit;
  routeId: string;
};

export const Details = ({ visit, routeId }: IDetails) => {
  const queryClient = useQueryClient();

  const {
    mutate: unassignGroupFromRouteMutation,
    isPending: isUnassigningGroupFromRoute,
  } = useMutation({
    mutationFn: ({ groupId }: { groupId: string }) =>
      unassignGroupFromRoute(groupId, routeId),
    onError: (error) => {
      displayErrorMessageAlert(
        `Gick inte att ta bort besöket från rutten. ${deducedError(error)}`,
      );
    },
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        }),
        queryClient.invalidateQueries({
          queryKey: shiftKeys.lists(),
        }),
      ]);
    },
  });

  const { mutate: deallocateShiftsMutation, isPending: isDeallocating } =
    useMutation({
      mutationFn: ({ shiftIds }: { shiftIds: number[] }) =>
        deallocateShiftsFromVisit(shiftIds, routeId, visit.id),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att ta bort arbetspasset från besöket. ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: () => {
        if (assignees.length === 1) {
          unassignGroupFromRouteMutation({ groupId: visit.id });
        } else {
          return Promise.all([
            queryClient.invalidateQueries({
              queryKey: activityOccurrenceAndGroupKeys.all(),
            }),
            queryClient.invalidateQueries({
              queryKey: shiftKeys.lists(),
            }),
          ]);
        }
      },
    });

  const { mutate: allocateShiftsMutation, isPending: isAllocating } =
    useMutation({
      mutationFn: ({ shiftIds }: { shiftIds: number[] }) =>
        allocateShiftsToVisit(shiftIds, routeId, visit.id),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att lägga till arbetspasset på besöket. ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: async () => {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.all(),
          }),
          queryClient.invalidateQueries({
            queryKey: shiftKeys.lists(),
          }),
        ]);
      },
    });

  const appendShift = (shiftIdToAdd: number) => {
    allocateShiftsMutation({
      shiftIds: [shiftIdToAdd],
    });
  };

  const removeShift = (shiftIdToRemove: number) => {
    deallocateShiftsMutation({
      shiftIds: [shiftIdToRemove],
    });
  };

  const { patient, occurrences, status } = visit;
  const statusTimestamp = getVisitStatusTimestamp(visit);
  const { start, end, isAnyTimeOfDay } = calculateVisitTimes(visit);
  const visitHasWellDefinedTime = start && end;
  const canMakeChangesToVisit = status !== visitStatusSchema.Values.finished;
  const { assignees } = visit;
  const assignedShiftIds = assignees.map(({ id }) => id);
  const unfulfilledRequirements = getUnfulfilledRequirements(visit);

  return (
    <div className={styles.details}>
      <ActivityGroupTag category={"HomeVisit"} patient={patient.name} />
      <div className={styles.titleTimeDescription}>
        <div className={styles.visitAndStatus}>
          <Text element="div" size="large" weight="medium">
            Hembesök
          </Text>
          <StatusTag
            status={status}
            timestamp={statusTimestamp}
            variant="icon-and-text-with-timestamp"
          />
        </div>

        {isAnyTimeOfDay
          ? timeOfDayDictionary.Any.long
          : visitHasWellDefinedTime
            ? formattedTimeSpan(start, end)
            : "-"}
      </div>
      <ul title="Aktiviteter">
        {occurrences.map((occurrence) => (
          <li key={occurrence.id}>
            <ActivityOccurrence activityOccurrence={occurrence} />
          </li>
        ))}
      </ul>
      {canMakeChangesToVisit ? (
        <>
          <QuickActivityInDetails groupId={visit.id} />
          <div className={styles.assignShifts}>
            <ShiftDropdownMenu
              assignedShiftIds={assignedShiftIds}
              unfulfilledRequirements={unfulfilledRequirements}
              onAllocate={appendShift}
              isAllocating={isAllocating}
              shiftsFromDate={startOfDay(
                visit.occurrences[0]
                  ? visit.occurrences[0].start
                  : startOfToday(),
              )}
            />
            {assignees.map((shift) => (
              <div key={shift.id}>
                <AssignedShiftChip
                  medicalCompetence={shift.competence}
                  shift={shift}
                  size="small"
                  onRemove={() => removeShift(shift.id)}
                  disabled={isDeallocating || isUnassigningGroupFromRoute}
                />
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export const ActivityDetails = ({
  activityOccurrence,
}: {
  activityOccurrence: IActivityOccurrence;
}) => {
  const {
    category,
    title,
    description,
    start,
    end,
    status: visitStatus,
    patient,
    timeOfDay,
    activityId,
    id,
  } = activityOccurrence;

  const queryClient = useQueryClient();

  const { mutate: allocateShiftsMutation, isPending: isAllocating } =
    useMutation({
      mutationFn: ({
        shiftIds,
        activityId,
        occurrenceId,
      }: {
        shiftIds: number[];
        activityId: string;
        occurrenceId: string;
      }) =>
        allocateShiftsToActivityOccurrence(shiftIds, activityId, occurrenceId),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att allokera arbetspasset till aktivitetstillfället. ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: shiftKeys.lists(),
          }),
        ]);
      },
    });

  const { mutate: deallocateShiftsMutation, isPending: isDeallocating } =
    useMutation({
      mutationFn: ({
        shiftIds,
        activityId,
        occurrenceId,
      }: {
        shiftIds: number[];
        activityId: string;
        occurrenceId: string;
      }) =>
        deallocateShiftsFromActivityOccurrence(
          shiftIds,
          activityId,
          occurrenceId,
        ),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att ta bort arbetspasset från aktivitetstillfället. ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: shiftKeys.lists(),
          }),
        ]);
      },
    });

  const appendShift = ({
    shiftIdToAdd,
    activityId,
    occurrenceId,
  }: {
    shiftIdToAdd: number;
    activityId: string;
    occurrenceId: string;
  }) => {
    allocateShiftsMutation({
      shiftIds: [shiftIdToAdd],
      activityId,
      occurrenceId,
    });
  };

  const removeShift = ({
    shiftIdToRemove,
    activityId,
    occurrenceId,
  }: {
    shiftIdToRemove: number;
    activityId: string;
    occurrenceId: string;
  }) => {
    deallocateShiftsMutation({
      shiftIds: [shiftIdToRemove],
      activityId,
      occurrenceId,
    });
  };

  const isAnyTimeOfDay = timeOfDay === "Any";
  if (category === "PatientMeasurementTask" || category === "PatientTask") {
    return null;
  }

  const statusTimestamp =
    getActivityOccurrenceStatusTimestamp(activityOccurrence);
  const canMakeChangesToVisit =
    visitStatus !== visitStatusSchema.Values.finished;
  const assignees = activityOccurrence.assignees;
  const assignedShiftIds = assignees.map(({ id }) => id);
  const unfulfilledRequirements = getUnfulfilledRequirements({
    assignees,
    occurrences: [activityOccurrence],
  });

  return (
    <div className={styles.details}>
      <ActivityGroupTag category={category} patient={patient?.name} />
      <div className={styles.titleTimeDescription}>
        <div className={styles.visitAndStatus}>
          <Text element="div" size="large" weight="medium">
            {title}
          </Text>
          <StatusTagWithDropdown
            status={visitStatus}
            category={category}
            activityId={activityId}
            occurrenceId={id}
            timestamp={statusTimestamp}
            variant="icon-and-text-with-timestamp"
          />
        </div>
        {isAnyTimeOfDay
          ? timeOfDayDictionary.Any.long
          : formattedTimeSpan(start, end)}
        {description ? <Text element="p">{description}</Text> : null}
      </div>
      {canMakeChangesToVisit ? (
        <div className={styles.assignShifts}>
          <ShiftDropdownMenu
            assignedShiftIds={assignedShiftIds}
            unfulfilledRequirements={unfulfilledRequirements}
            onAllocate={(shiftId: number) =>
              appendShift({
                shiftIdToAdd: shiftId,
                activityId: activityOccurrence.activityId,
                occurrenceId: activityOccurrence.id,
              })
            }
            isAllocating={isAllocating}
            shiftsFromDate={startOfDay(activityOccurrence.start)}
          />
          {assignees.map((shift) => (
            <div key={shift.id}>
              <AssignedShiftChip
                medicalCompetence={shift.competence}
                shift={shift}
                size="small"
                onRemove={() =>
                  removeShift({
                    shiftIdToRemove: shift.id,
                    activityId: activityOccurrence.activityId,
                    occurrenceId: activityOccurrence.id,
                  })
                }
                disabled={isDeallocating}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
