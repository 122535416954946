import { useEffect, useId, useState } from "react";
import type { IRoute } from "@/api/Routes";
import styles from "./WorkBlockSection.module.scss";
import { Heading } from "@components/Heading/Heading";
import { WorkBlock } from "./WorkBlock";
import { useSelectedDateHasPassed } from "@/Utils/useSelectedDateHasPassed";
import { clsx } from "clsx";
import CaretDownIcon from "@components/icons/CaretDownIcon";
import CaretUpIcon from "@components/icons/CaretUpIcon";
import type { IBlockStatus } from "@models/blocks";
import { blockStatusDictionary } from "@models/blocks";
import NoResults from "@/components/NoResults/NoResults";

type IWorkBlockSection = {
  blockSectionStatus: IBlockStatus;
  blocks: IRoute[];
};

export const WorkBlockSection = ({
  blockSectionStatus,
  blocks,
}: IWorkBlockSection) => {
  const [isExpanded, setIsExpanded] = useState(blocks.length > 0);
  const [blocksAmount, setBlocksAmount] = useState(blocks.length);
  const contentId = useId();
  const selectedDateHasPassed = useSelectedDateHasPassed();
  const blockStatusName = blockStatusDictionary[blockSectionStatus].sv;

  /* If the number of blocks is increased, expand the block */
  useEffect(() => {
    if (blocks.length > blocksAmount) {
      setIsExpanded(true);
    }
    setBlocksAmount(blocks.length);
  }, [blocks.length, blocksAmount]);

  return (
    <section
      className={clsx([
        styles.blockSection,
        styles[`status-${blockSectionStatus}`],
      ])}
    >
      <button
        aria-expanded={isExpanded}
        aria-controls={contentId}
        className={styles.blockSectionHeadingButton}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        {isExpanded ? <CaretUpIcon /> : <CaretDownIcon />}
        <Heading id="block-heading-planned" level="h2" size="h3">
          {blockStatusName} ({blocks.length})
        </Heading>
      </button>
      {isExpanded ? (
        <div id={contentId}>
          {blocks.length === 0 ? (
            <NoResults
              message={`Inga ${blockStatusName.toLowerCase()} rutter.`}
            />
          ) : (
            <ul
              aria-labelledby="block-heading-planned"
              className={styles.block}
            >
              {blocks.map((block) => (
                <li key={block.id}>
                  <WorkBlock
                    block={block}
                    isEditable={
                      !selectedDateHasPassed && block.status !== "finished"
                    }
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : null}
    </section>
  );
};
