import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Link } from "@components/Link/Link";
import {
  cancelTravellingToVisit,
  finishVisit,
  routeKeys,
  startTravellingToVisit,
  startVisit,
  useOngoingVisit,
  useUpcomingVisit,
  useVisit,
  visitKeys,
} from "@/api/Routes";
import {
  FilledButton,
  OutlinedButton,
  PlainButton,
} from "@components/Button/Button";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import PinIcon from "@components/icons/PinIcon";
import styles from "./$visitId.module.scss";
import clsx from "clsx";
import { StatusBar } from "@/components/StatusBar/StatusBar";
import PhoneIcon from "@components/icons/PhoneIcon";
import { Dialog } from "@components/Dialog/Dialog";
import { useState } from "react";
import {
  deducedError,
  displayErrorMessageAlert,
  isErrorWithKnownErrorCode,
  knownErrorCodeSchema,
} from "@/Utils/ErrorUtils";
import { Timeline, TimelineItem } from "@/components/Timeline/Timeline";
import { PostRegisterActivity } from "./PostRegisterActivity";
import { ActivityOccurrenceCheckbox } from "./ActivityOccurrenceCheckbox";
import { NonInteractiveActivityOccurrence } from "./NonInteractiveActivityOccurrence";
import { z } from "zod";
import { TextWithCheckOrCross } from "@/components/TextWithCheckOrCross/TextWithCheckOrCross";
import PlusIcon from "@components/icons/PlusIcon";
import { CallChooser } from "@/components/CallChooser/CallChooser";
import { NavCard } from "@/components/NavCard/NavCard";
import PersonInformationIcon from "@components/icons/PersonInformationIcon";
import { Heading } from "@components/Heading/Heading";
import { getPatientNameWithStatus } from "@/api/Patients";
import HiddenIcon from "@components/icons/HiddenIcon";
import { Text } from "@components/Text/Text";
import { PersonalIdentityNumber } from "@/components/PersonalIdentityNumber/PersonalIdentityNumber";
import * as Sentry from "@sentry/react";
import { format } from "@models/date-and-time";
import DocumentWithTextIcon from "@components/icons/DocumentWithTextIcon";

const CallPatientOrRelatives = ({
  patient,
  relatives,
}: {
  patient: { name: string; phoneNumber?: string | null | undefined };
  relatives: ReadonlyArray<{ name: string; phoneNumber: string }>;
}) => {
  return patient.phoneNumber && relatives.length >= 1 ? (
    <CallChooser
      contacts={[
        {
          name: patient.name,
          phoneNumber: patient.phoneNumber,
        },
        ...relatives,
      ]}
    />
  ) : patient.phoneNumber ? (
    <Link
      aria-label={`Ring ${patient.name}`}
      className={styles.phoneLink}
      type="phone"
      to={patient.phoneNumber}
    >
      <PhoneIcon />
    </Link>
  ) : (
    // Workaround for scenario where patient has no phone number.
    // This only happens for Prospect/Declined as of 2023-11-03.
    // Therefore, it is very unlikely to happen and we can live with this workaround.
    <div>
      <PhoneIcon /> saknas
    </div>
  );
};

const VisitDetails = () => {
  const { routeId, visitId } = z
    .object({ routeId: z.string(), visitId: z.string() })
    .parse(useParams());
  const {
    data: visit,
    isPending,
    isError,
    error: getVisitError,
  } = useVisit(routeId, visitId);
  const { data: upcomingVisit } = useUpcomingVisit(routeId);
  const { data: ongoingVisit } = useOngoingVisit(routeId);
  const [
    isTryingToFinishVisitWithoutCompletingAllActivityOccurrences,
    setIsTryingToFinishVisitWithoutCompletingAllActivityOccurrences,
  ] = useState(false);
  const [isAddingNewActivityOccurrence, setIsAddingNewActivityOccurrence] =
    useState(false);

  const queryClient = useQueryClient();
  const {
    mutate: startTravellingToVisitMutation,
    isPending: isStartingTravellingToVisit,
  } = useMutation({
    mutationFn: ({ routeId, visitId }: { routeId: string; visitId: string }) =>
      startTravellingToVisit(routeId, visitId),
    onSuccess: () => {
      return queryClient.refetchQueries({ queryKey: routeKeys.all });
    },
    onError: (error) => {
      // UI out of sync. Reset cache and move on.
      if (
        isErrorWithKnownErrorCode(error) &&
        (error.response.data.code ===
          knownErrorCodeSchema.Values.AlreadyTravellingToVisit ||
          error.response.data.code ===
            knownErrorCodeSchema.Values.AlreadyTravelledToVisit)
      ) {
        Sentry.captureException(
          "Failed to start travelling. Trip was already started. Refetching.",
        );
        return queryClient.refetchQueries({ queryKey: routeKeys.all });
      } else {
        displayErrorMessageAlert(
          `Gick inte att påbörja resan till besöket. ${deducedError(error)}`,
        );
      }
    },
  });

  const { mutate: startVisitMutation, isPending: isStartingVisit } =
    useMutation({
      mutationFn: ({
        routeId,
        visitId,
      }: {
        routeId: string;
        visitId: string;
      }) => startVisit(routeId, visitId),
      onSuccess: () => {
        return queryClient.refetchQueries({ queryKey: routeKeys.all });
      },
      onError: (error) => {
        if (
          isErrorWithKnownErrorCode(error) &&
          (error.response.data.code ===
            knownErrorCodeSchema.Values.OngoingVisit ||
            error.response.data.code ===
              knownErrorCodeSchema.Values.FinishedVisit)
        ) {
          Sentry.captureException(
            "Failed to start visit. Visit was already started. Refetching.",
          );
          return queryClient.refetchQueries({ queryKey: routeKeys.all });
        } else {
          displayErrorMessageAlert(
            `Gick inte att påbörja besöket. ${deducedError(error)}`,
          );
        }
      },
    });

  const { mutate: finishVisitMutation, isPending: isFinishingVisit } =
    useMutation({
      mutationFn: ({
        routeId,
        visitId,
      }: {
        routeId: string;
        visitId: string;
      }) => finishVisit(routeId, visitId),
      onSuccess: async () => {
        await queryClient.refetchQueries({ queryKey: routeKeys.all });
        setIsTryingToFinishVisitWithoutCompletingAllActivityOccurrences(false);
      },
      onError: (error) => {
        if (
          isErrorWithKnownErrorCode(error) &&
          error.response.data.code === knownErrorCodeSchema.Values.FinishedVisit
        ) {
          Sentry.captureException(
            "Failed to finish visit. Visit was already finished. Refetching.",
          );

          return queryClient.refetchQueries({ queryKey: routeKeys.all });
        } else {
          displayErrorMessageAlert(
            `Gick inte att avsluta besöket. ${deducedError(error)}`,
          );
        }
      },
    });

  const { mutate: cancelTravellingToVisitMutation } = useMutation({
    mutationFn: ({ routeId, visitId }: { routeId: string; visitId: string }) =>
      cancelTravellingToVisit(routeId, visitId),
    onSuccess: () => {
      return queryClient.refetchQueries({
        queryKey: visitKeys(routeId).detail(visitId),
      });
    },
    onError: (error) => {
      if (
        isErrorWithKnownErrorCode(error) &&
        error.response.data.code ===
          knownErrorCodeSchema.Values.UnstartedTripToVisit
      ) {
        Sentry.captureException(
          "Failed to cancel travelling to visit. Visit was not being travelled to. Refetching.",
        );
        return queryClient.refetchQueries({ queryKey: routeKeys.all });
      } else {
        displayErrorMessageAlert(
          `Gick inte att avbryta resan till besöket. ${deducedError(error)}`,
        );
      }
    },
  });

  if (isPending) {
    return <Loading message="Laddar besöksdetaljer" />;
  }

  if (isError) {
    Sentry.captureException(getVisitError);
    return (
      <ErrorMessage
        message={`Kunde inte hämta besöksdetaljerna. ${deducedError(getVisitError)}`}
      />
    );
  }

  const visitTitle =
    visit.status === "ongoing" && visit.startedAt
      ? `Besök pågår sedan ${format(visit.startedAt, "HH:mm")}`
      : visit.status === "finished" && visit.startedAt
        ? `Besök startat ${format(visit.startedAt, "HH:mm")}`
        : "Besök";

  const finishedTitle = visit.finishedAt
    ? `Klar ${format(visit.finishedAt, "HH:mm")}`
    : "Klar";

  return (
    <div className={styles.visitDetailsWrapper}>
      {ongoingVisit && ongoingVisit.id !== visit.id ? (
        <div className={styles.statusBarWrapper}>
          <StatusBar>
            <Link to={`../${ongoingVisit.id}`} relative="path">{`${
              ongoingVisit.status === "travellingTo"
                ? "Pågående resa till"
                : "Pågående besök hos"
            } ${ongoingVisit.patient.name}`}</Link>
          </StatusBar>
        </div>
      ) : (
        <></>
      )}
      <article className={styles.visitDetails}>
        <header className={styles.visitDetailsHeader}>
          <div className={styles.nameAndPnr}>
            <Heading level="h2">
              {getPatientNameWithStatus(visit.patient)}
            </Heading>
            <Text element="div" size="large">
              <PersonalIdentityNumber
                personalIdentityNumber={visit.patient.personalIdentityNumber}
              />
            </Text>
          </div>
          <CallPatientOrRelatives
            patient={visit.patient}
            relatives={visit.patient.relatives.map(
              ({ name, relation, phoneNumber }) => {
                return { name: `${name} (${relation})`, phoneNumber };
              },
            )}
          />
        </header>
        <Timeline>
          <TimelineItem
            title={visit.status === "travellingTo" ? "Resa pågår" : "Resa"}
            state={
              visit.status === "planned"
                ? "initial"
                : visit.status === "travellingTo"
                  ? "ongoing"
                  : "finished"
            }
            relatedAction={
              visit.status === "travellingTo"
                ? {
                    label: "Avbryt",
                    onClick: () =>
                      cancelTravellingToVisitMutation({
                        routeId: visit.routeId,
                        visitId: visit.id,
                      }),
                  }
                : undefined
            }
          >
            <div className={styles.timelineItemContent}>
              {visit.patient.address.additionalInformation ? (
                <p>{visit.patient.address.additionalInformation}</p>
              ) : undefined}
              {visit.status === "planned" ? (
                <>
                  <p>{`${visit.patient.address.addressLine1}, ${visit.patient.address.city}`}</p>
                  <FilledButton
                    onClick={() =>
                      startTravellingToVisitMutation({
                        routeId: visit.routeId,
                        visitId: visit.id,
                      })
                    }
                    disabled={isStartingTravellingToVisit}
                    width="fill-container"
                  >
                    Starta resa
                  </FilledButton>
                </>
              ) : visit.status === "travellingTo" ? (
                <>
                  <div className={styles.gpsNavigation}>
                    <PinIcon />
                    <Link
                      type="external"
                      // https://developers.google.com/maps/documentation/urls/get-started#directions-action
                      to={`https://www.google.com/maps/dir/?api=1&destination=${visit.patient.address.coordinates.latitude},${visit.patient.address.coordinates.longitude}&travelmode=driving`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`${visit.patient.address.addressLine1}, ${visit.patient.address.city}`}
                    </Link>
                  </div>
                  <OutlinedButton
                    onClick={() =>
                      startVisitMutation({
                        routeId: visit.routeId,
                        visitId: visit.id,
                      })
                    }
                    disabled={isStartingVisit}
                    width="fill-container"
                  >
                    Påbörja besök
                  </OutlinedButton>
                </>
              ) : (
                <></>
              )}
            </div>
          </TimelineItem>
          <TimelineItem
            title={visitTitle}
            state={
              visit.status === "planned" || visit.status === "travellingTo"
                ? "initial"
                : visit.status === "ongoing"
                  ? "ongoing"
                  : "finished"
            }
          >
            <div className={styles.timelineItemContent}>
              <ul
                className={clsx(
                  styles.activityOccurrenceList,
                  visit.status !== "ongoing" &&
                    styles.previewedActivityOccurrenceList,
                )}
              >
                {visit.occurrences.map((occurrence) => (
                  <li
                    key={`${occurrence.activityId}${occurrence.id}`}
                    className={styles.activityOccurrence}
                  >
                    <div className={styles.titleWithIcon}>
                      {visit.status === "ongoing" ? (
                        <ActivityOccurrenceCheckbox
                          occurrence={occurrence}
                          refetchVisitWithOccurrences={() =>
                            queryClient.refetchQueries({
                              queryKey: visitKeys(routeId).detail(visitId),
                            })
                          }
                        />
                      ) : visit.status === "finished" ? (
                        <TextWithCheckOrCross
                          condition={occurrence.status === "finished"}
                          text={occurrence.title}
                        />
                      ) : (
                        <>
                          <p>{occurrence.title}</p>
                        </>
                      )}
                      {occurrence.hidden ? <HiddenIcon /> : null}
                    </div>
                    {occurrence.description ? (
                      <div className={styles.description}>
                        <DocumentWithTextIcon />
                        <Text element="p" size="small" color="faded">
                          {occurrence.description}
                        </Text>
                      </div>
                    ) : undefined}
                  </li>
                ))}
                {visit.status === "ongoing" ? (
                  <PlainButton
                    onClick={() => setIsAddingNewActivityOccurrence(true)}
                  >
                    <PlusIcon />
                    Lägg till
                  </PlainButton>
                ) : undefined}
              </ul>
              {visit.status === "ongoing" ? (
                visit.occurrences.every(
                  ({ status }) => status === "finished",
                ) ? (
                  <FilledButton
                    width="fill-container"
                    onClick={() =>
                      finishVisitMutation({
                        routeId: visit.routeId,
                        visitId: visit.id,
                      })
                    }
                    disabled={isFinishingVisit}
                  >
                    Slutför besök
                  </FilledButton>
                ) : (
                  <OutlinedButton
                    width="fill-container"
                    onClick={() =>
                      setIsTryingToFinishVisitWithoutCompletingAllActivityOccurrences(
                        true,
                      )
                    }
                  >
                    Slutför besök
                  </OutlinedButton>
                )
              ) : (
                <></>
              )}
            </div>
          </TimelineItem>
          <TimelineItem
            title={finishedTitle}
            state={
              visit.status === "planned" ||
              visit.status === "travellingTo" ||
              visit.status === "ongoing"
                ? "initial"
                : "finished"
            }
          >
            {visit.status === "finished" ? (
              <span className={styles.upcomingVisitLinkWrapper}>
                {upcomingVisit ? (
                  <Link to={`../${upcomingVisit.id}`} relative="path">
                    Gå till nästa besök
                  </Link>
                ) : (
                  <Link to={`..`}>Gå tillbaka till listan</Link>
                )}
              </span>
            ) : (
              <></>
            )}
          </TimelineItem>
        </Timeline>
      </article>

      <NavCard
        title={<Heading level="h2">Patientinformation</Heading>}
        to={`/ambulating/patients/${visit.patientId}`}
        icon={<PersonInformationIcon />}
      />

      <Dialog
        isOpen={isAddingNewActivityOccurrence}
        onClose={() => setIsAddingNewActivityOccurrence(false)}
        title="Efterregistrera aktivitet"
        position="bottom"
      >
        <PostRegisterActivity
          onSuccess={async () => {
            setIsAddingNewActivityOccurrence(false);
            return queryClient.invalidateQueries({
              queryKey: visitKeys(routeId).detail(visitId),
            });
          }}
          patientId={visit.patient.id}
          routeId={visit.routeId}
          visitId={visitId}
        />
      </Dialog>
      <Dialog
        isOpen={isTryingToFinishVisitWithoutCompletingAllActivityOccurrences}
        onClose={() =>
          setIsTryingToFinishVisitWithoutCompletingAllActivityOccurrences(false)
        }
        title="Alla aktiviteter är inte utförda"
        position="bottom"
      >
        <div className={styles.undoneActivityOccurrenceDialogContent}>
          <ul className={styles.undoneActivityOccurrenceList}>
            {visit.occurrences.map(({ activityId, id, title, status }) => {
              return (
                <li key={`${activityId}${id}`}>
                  <NonInteractiveActivityOccurrence
                    status={status}
                    title={title}
                    displayStatus={true}
                  />
                </li>
              );
            })}
          </ul>
          <p>Vill du ändå slutföra ditt besök?</p>
          <div className={styles.buttonGroup}>
            <OutlinedButton
              onClick={() =>
                setIsTryingToFinishVisitWithoutCompletingAllActivityOccurrences(
                  false,
                )
              }
              width="fill-container"
            >
              Avbryt
            </OutlinedButton>
            <FilledButton
              onClick={() =>
                finishVisitMutation({
                  routeId: visit.routeId,
                  visitId: visit.id,
                })
              }
              disabled={isFinishingVisit}
              width="fill-container"
            >
              Slutför besök
            </FilledButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default VisitDetails;
