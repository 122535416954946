import { useFormContext } from "react-hook-form";
import type { IAddActivityFormData } from "./AddActivityForm";
import styles from "./ActivityTypePicker.module.scss";
import RadioButton from "@/components/RadioButton/RadioButton";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import type { IActivityCategory } from "@models/activities";
import { categorySchema } from "@models/activities";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import { activityCategoryDictionary } from "@/api/Activities";

type IActivityTypePicker = {
  categoryConditionals: {
    currentCategoryRequiresCompetence: boolean;
    currentCategoryMayRequireDoubleStaffing: boolean;
  };
};

export const ActivityTypePicker = ({
  categoryConditionals,
}: IActivityTypePicker) => {
  const {
    formState: { errors },
    register,
    resetField,
  } = useFormContext<IAddActivityFormData>();
  const CategoryRadioButton = (category: IActivityCategory) => (
    <div className={styles.categoryRadioButton}>
      <CategoryIcon category={category} size="small" />
      {activityCategoryDictionary[category]}
    </div>
  );

  return (
    <RadioButtons
      errorMessage={errors.category?.message}
      orientation="horizontal"
    >
      {/* Only one RadioButton has to hold additional logic, applies to whole registration. */}
      <RadioButton
        label={{
          text: activityCategoryDictionary[categorySchema.Values.HomeVisit],
          component: CategoryRadioButton(categorySchema.Values.HomeVisit),
        }}
        visualStyle="framed"
        value={categorySchema.Values.HomeVisit}
        {...register(`category`, {
          required: {
            value: true,
            message: "Aktiviteten behöver tillhöra en kategori.",
          },
          onChange: () => {
            if (!categoryConditionals.currentCategoryRequiresCompetence) {
              resetField(`requiredCompetences`);
            }
            if (!categoryConditionals.currentCategoryMayRequireDoubleStaffing) {
              resetField(`doubleStaffing`);
            }
          },
        })}
      />
      <RadioButton
        label={{
          text: activityCategoryDictionary[categorySchema.Values.VideoCall],
          component: CategoryRadioButton(categorySchema.Values.VideoCall),
        }}
        visualStyle="framed"
        value={categorySchema.Values.VideoCall}
        {...register(`category`)}
      />
      <RadioButton
        label={{
          text: activityCategoryDictionary[categorySchema.Values.PatientTask],
          component: CategoryRadioButton(categorySchema.Values.PatientTask),
        }}
        visualStyle="framed"
        value={categorySchema.Values.PatientTask}
        {...register(`category`)}
      />
      <RadioButton
        label={{
          text: activityCategoryDictionary[
            categorySchema.Values.PatientMeasurementTask
          ],
          component: CategoryRadioButton(
            categorySchema.Values.PatientMeasurementTask,
          ),
        }}
        visualStyle="framed"
        value={categorySchema.Values.PatientMeasurementTask}
        {...register(`category`)}
      />
      <RadioButton
        label={{
          text: activityCategoryDictionary[categorySchema.Values.AdminTask],
          component: CategoryRadioButton(categorySchema.Values.AdminTask),
        }}
        visualStyle="framed"
        value={categorySchema.Values.AdminTask}
        {...register(`category`)}
      />
    </RadioButtons>
  );
};
