import type { A11yDialogInstance } from "react-a11y-dialog";
import { A11yDialog } from "react-a11y-dialog";
import { useCallback, useEffect, useId, useRef } from "react";
import styles from "./Dialog.module.scss";
import CrossIcon from "../icons/CrossIcon";
import clsx from "clsx";
import { IconButton } from "../Button/Button";

interface IDialog {
  children?: JSX.Element | JSX.Element[];
  hideTitle?: boolean; // title is required for accessibility reasons (to identify the dialog), but it can be visually hidden if it is conveyed visually by other means.
  isOpen: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  position?: "bottom" | "center";
  title: string;
}

export const Dialog = ({
  children,
  hideTitle = false,
  isOpen,
  onClose,
  onOpen,
  position = "center",
  title, // Dialog is now concerned with a mix of layout and content. Prefer to let `children` provide its own title without introducing accessibility concerns.
}: IDialog) => {
  const dialogId = useId();
  const dialogRef = useRef<A11yDialogInstance>();

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.show();
    } else {
      dialogRef.current?.hide();
    }
  }, [isOpen]);

  const onDialogCreation = useCallback(
    (instance: A11yDialogInstance | undefined) => {
      dialogRef.current = instance;
      if (isOpen) {
        dialogRef.current?.show();
      }
      dialogRef.current?.on("hide", () => {
        onClose?.();
      });
      dialogRef.current?.on("show", () => {
        onOpen?.();
      });
    },
    // We wish to invoke "creation" logic only once. Sorry React purists. And future me if this bites us in the ass one day.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <A11yDialog
      classNames={{
        container: clsx(styles.container, styles[position]),
        overlay: styles.overlay,
        dialog: clsx(styles.content, styles[position]),
        title: clsx(
          position === "bottom" ? "h3" : "h1",
          styles.title,
          hideTitle && styles.hideTitle,
        ),
      }}
      closeButtonPosition="none"
      dialogRef={onDialogCreation}
      id={dialogId}
      title={title}
    >
      <div
        className={clsx(styles.closeButton, {
          visuallyHidden: position === "bottom",
        })}
      >
        <IconButton
          onClick={() => dialogRef.current?.hide()}
          aria-label="Stäng"
        >
          <CrossIcon />
        </IconButton>
      </div>
      {children}
    </A11yDialog>
  );
};
