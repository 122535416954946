import { fetchCoordinates, patientKeys, updateAddress } from "@/api/Patients";
import Form from "@/components/Form/Form";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError } from "@/Utils/ErrorUtils";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import type { ViewState } from "react-map-gl";
import { Marker } from "react-map-gl";
import { FilledButton } from "@components/Button/Button";
import { useCallback } from "react";
import type { ICoordinatePair } from "@/components/Map/Map";
import Map from "@/components/Map/Map";
import type { IAddressWithCoordinates } from "@models/addresses";
import { useState, useEffect } from "react";
import { HOSPITAL_COORDINATES } from "@/Utils/EnvUtils";
import InputField from "@/components/InputField/InputField";
import TextArea from "@/components/TextArea/TextArea";
import styles from "./EditAddress.module.scss";
import { useFeatureFlag } from "@/api/FeatureFlags";

const formatAddress = ({
  addressLine1,
  city,
  postalCode,
  additionalInformation,
  coordinates,
}: IAddressWithCoordinates) => {
  return {
    addressLine1,
    city,
    // Remove all spaces from postal code before sending to backend
    postalCode: postalCode.replaceAll(" ", ""),
    additionalInformation,
    coordinates: {
      // @ts-expect-error Longitude is usually a number, but can be a string if the input fields have been manually touched.
      longitude: parseFloat(coordinates.longitude),
      // @ts-expect-error Latitude is usually a number, but can be a string if the input fields have been manually touched.
      latitude: parseFloat(coordinates.latitude),
    },
  };
};

export const EditAddress = ({
  currentAddress,
  onSuccess,
  patientId,
}: {
  currentAddress: Omit<IAddressWithCoordinates, "addressLine2">;
  onSuccess: () => void;
  patientId: string;
}) => {
  const { data: mapboxGeocodingV6, isPending: isPendingFeatureFlagResolution } =
    useFeatureFlag("MapboxGeocodingV6");
  const {
    formState: { errors, dirtyFields, isDirty },
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    reset,
    trigger,
    watch,
  } = useForm<IAddressWithCoordinates>({
    defaultValues: {
      ...currentAddress,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (address: IAddressWithCoordinates) =>
      updateAddress(patientId, address),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const updatedAddress = formatAddress(formData);

    mutate(updatedAddress);
  });

  const setCoordinates = useCallback(
    (coordinates: ICoordinatePair) => setValue("coordinates", coordinates),
    [setValue],
  );

  const triggerCoordinateValidation = useCallback(
    () => trigger(["coordinates.latitude", "coordinates.longitude"]),
    [trigger],
  );

  const { addressLine1, postalCode, city, coordinates } = watch();
  const coordinatesProvided =
    Boolean(coordinates.latitude && coordinates.longitude) ||
    Boolean(
      dirtyFields.coordinates?.latitude || dirtyFields.coordinates?.longitude,
    );

  const [mapState, setMapState] = useState<ViewState>({
    longitude: coordinates.longitude || HOSPITAL_COORDINATES.longitude,
    latitude: coordinates.latitude || HOSPITAL_COORDINATES.latitude,
    zoom: 13,
    bearing: 0,
    pitch: 0,
    padding: { top: 0, left: 0, right: 0, bottom: 0 },
  });
  const [coordinatesFailedToFetch, setCoordinatesFailedToFetch] =
    useState(false);

  useEffect(() => {
    if (coordinates.longitude && coordinates.latitude)
      setMapState((mapState) => ({
        ...mapState,
        // We know these are not `undefined` since we passed the if statement above.
        longitude: coordinates.longitude!,
        latitude: coordinates.latitude!,
      }));
  }, [coordinates.latitude, coordinates.longitude]);

  useEffect(() => {
    if (isPendingFeatureFlagResolution) {
      return;
    }
    const handler = setTimeout(() => {
      if (addressLine1 && postalCode && (mapboxGeocodingV6 || city)) {
        fetchCoordinates(
          mapboxGeocodingV6
            ? {
                addressLine1,
                postalCode,
                apiVersion: "6",
              }
            : {
                addressLine1,
                postalCode,
                city,
                apiVersion: "5",
              },
        )
          .then((coordinates) => {
            setCoordinatesFailedToFetch(false);
            setCoordinates(coordinates);
            triggerCoordinateValidation();
            setMapState((mapState) => ({
              ...mapState,
              longitude: coordinates.longitude,
              latitude: coordinates.latitude,
            }));
          })
          .catch(() => {
            setCoordinatesFailedToFetch(true);
          });
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [
    addressLine1,
    postalCode,
    city,
    setCoordinates,
    triggerCoordinateValidation,
    mapboxGeocodingV6,
    isPendingFeatureFlagResolution,
  ]);

  return (
    <Form onSubmit={validateAndSubmit}>
      {isPending || isSuccess ? (
        <Loading message="Ändrar addressen" />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <>
            <InputField
              label="Gata"
              errorMessage={errors.addressLine1?.message}
              {...register("addressLine1", {
                required: { value: true, message: "Gata behövs." },
              })}
            />
            <Form.Row>
              <InputField
                label="Postnummer"
                errorMessage={errors.postalCode?.message}
                {...register("postalCode", {
                  required: { value: true, message: "Postnummer behövs." },
                  pattern: {
                    value: /^\d{3}\s?\d{2}$/,
                    message: "Postnumret måste anges som 5 siffror.",
                  },
                })}
              />
              <InputField
                label="Postort"
                errorMessage={errors.city?.message}
                {...register("city", {
                  required: { value: true, message: "Postort behövs." },
                })}
              />
            </Form.Row>
            <TextArea
              label="Övrig adressinformation (valfri)"
              {...register("additionalInformation")}
              formatHint="T.ex. har nyckel, portkod"
            />
            <Form.Row>
              <fieldset
                className={styles.fieldset}
                disabled={!coordinatesProvided && !coordinatesFailedToFetch}
              >
                <legend>
                  {!coordinatesProvided && !coordinatesFailedToFetch
                    ? "Fyll i address för att se plats"
                    : coordinatesFailedToFetch
                      ? "Kartan kan inte laddas. Fyll i koordinater manuellt."
                      : "Plats"}
                </legend>
                <div className={styles.mapAndCoordinatesSection}>
                  <Map
                    disabled={!coordinatesProvided}
                    id="registerPatientMap"
                    viewState={mapState}
                    setViewState={setMapState}
                    style={{ height: "30vh" }}
                  >
                    {coordinatesProvided &&
                    !coordinatesFailedToFetch &&
                    coordinates.longitude &&
                    coordinates.latitude ? (
                      <Marker
                        longitude={coordinates.longitude}
                        latitude={coordinates.latitude}
                      />
                    ) : (
                      <></>
                    )}
                  </Map>
                  <div className={styles.coordinateSection}>
                    <Form.Row>
                      <InputField
                        label="Longitud"
                        errorMessage={errors.coordinates?.longitude?.message}
                        {...register("coordinates.longitude", {
                          required: {
                            value: true,
                            message: "Longitud behöver anges.",
                          },
                          min: {
                            value: 17,
                            message: "Longitud bör vara över 17.",
                          },
                          max: {
                            value: 19,
                            message: "Longitud bör vara under 19.",
                          },
                        })}
                      />
                      <InputField
                        label="Latitud"
                        errorMessage={errors.coordinates?.latitude?.message}
                        {...register("coordinates.latitude", {
                          required: {
                            value: true,
                            message: "Latitud behöver anges.",
                          },
                          min: {
                            value: 58.3,
                            message: "Latitud bör vara över 58.3.",
                          },
                          max: {
                            value: 60.3,
                            message: "Latitud bör vara under 60.3.",
                          },
                        })}
                      />
                    </Form.Row>
                    {coordinatesProvided ? (
                      <p>
                        Fyll i koordinater manuellt om kartnålen är felplacerad.
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </fieldset>
            </Form.Row>
          </>
          <FilledButton type="submit">Spara ändringar</FilledButton>
        </>
      )}
    </Form>
  );
};
