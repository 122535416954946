import type { IFilledButton } from "../Button/Button";
import { FilledButton } from "../Button/Button";
import ArrowUpIcon from "../icons/ArrowUpIcon";

type IRetrieveHistoryButton = Omit<IFilledButton, "children">;

export const RetrieveHistoryButton = (props: IRetrieveHistoryButton) => {
  return (
    <FilledButton contentType="text-and-icon" {...props}>
      <ArrowUpIcon /> Visa tidigare meddelanden
    </FilledButton>
  );
};
