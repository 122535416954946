import { deducedError } from "@/Utils/ErrorUtils";
import { useRoutes } from "@/api/Routes";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Heading } from "@components/Heading/Heading";
import { Loading } from "@components/Loading/Loading";
import styles from "./index.module.scss";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { WorkBlockSection } from "./WorkBlockSection";
import * as Sentry from "@sentry/react";

export const WorkBlocks = () => {
  const selectedDate = useSelectedDate();
  const {
    data: blocks,
    isPending,
    isError,
    error,
  } = useRoutes({ date: selectedDate });

  if (isPending) {
    return <Loading message="Hämtar rutter" padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Gick inte att hämta rutterna. ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  const draftBlocks = blocks.filter((block) => block.status === "draft");
  const ongoingBlocks = blocks.filter((block) => block.status === "ongoing");
  const finishedBlocks = blocks.filter((block) => block.status === "finished");

  return (
    <article className={styles.container}>
      <Heading level="h1" className={styles.header}>
        Rutter
      </Heading>
      <WorkBlockSection blockSectionStatus={"draft"} blocks={draftBlocks} />
      <WorkBlockSection blockSectionStatus={"ongoing"} blocks={ongoingBlocks} />
      <WorkBlockSection
        blockSectionStatus={"finished"}
        blocks={finishedBlocks}
      />
    </article>
  );
};
