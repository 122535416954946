import DropdownMenu from "../DropdownMenu/DropdownMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  activityOccurrenceAndGroupKeys,
  finishActivityOccurrence,
  resetActivityOccurrence,
} from "@/api/Activities";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import type { IStatusTag } from "../StatusTag/StatusTag";
import { StatusTag } from "../StatusTag/StatusTag";
import type { IActivityCategory } from "@models/activities";
import { shiftKeys } from "@/api/Shifts";

type IStatusTagWithDropdown = IStatusTag & {
  category: IActivityCategory;
  activityId: string;
  occurrenceId: string;
};

export const StatusTagWithDropdown = ({
  status,
  variant = "icon",
  size = "small",
  timestamp,
  category,
  activityId,
  occurrenceId,
}: IStatusTagWithDropdown) => {
  const queryClient = useQueryClient();

  const { mutate: finishActivityOccurrenceMutation } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
    }: {
      activityId: string;
      occurrenceId: string;
    }) => finishActivityOccurrence(activityId, occurrenceId),
    onError: (error) => {
      displayErrorMessageAlert(
        `Gick inte att markera aktivitetstillfället som utfört. ${deducedError(
          error,
        )}`,
      );
    },
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        }),
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${occurrenceId}`,
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: shiftKeys.lists(),
        }),
      ]);
    },
  });

  const { mutate: resetActivityOccurrenceMutation } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
    }: {
      activityId: string;
      occurrenceId: string;
    }) => resetActivityOccurrence(activityId, occurrenceId),
    onError: (error) => {
      displayErrorMessageAlert(
        `Gick inte att ångra utfört aktivitetstillfälle. ${deducedError(
          error,
        )}`,
      );
    },
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        }),
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.detail(
            `${activityId}${occurrenceId}`,
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: shiftKeys.lists(),
        }),
      ]);
    },
  });

  if (category === "HomeVisit") {
    return <StatusTag status={status} variant={variant} size={size} />;
  }

  if (status !== "finished") {
    return (
      <DropdownMenu
        trigger={{
          custom: (
            <StatusTag
              status={status}
              variant={variant}
              size={size}
              timestamp={timestamp}
            />
          ),
        }}
      >
        <DropdownMenu.Item
          action={() =>
            finishActivityOccurrenceMutation({ activityId, occurrenceId })
          }
          content="Markera som utfört"
        />
      </DropdownMenu>
    );
  }

  if (status === "finished") {
    return (
      <DropdownMenu
        trigger={{
          custom: (
            <StatusTag
              status={status}
              variant={variant}
              size={size}
              timestamp={timestamp}
            />
          ),
        }}
      >
        <DropdownMenu.Item
          action={() =>
            resetActivityOccurrenceMutation({ activityId, occurrenceId })
          }
          content="Ångra utfört"
        />
      </DropdownMenu>
    );
  }

  return (
    <StatusTag
      status={status}
      variant={variant}
      size={size}
      timestamp={timestamp}
    />
  );
};
