import type { IRouteStatus } from "@/api/Routes";
import { useDirections, useRoutes } from "@/api/Routes";
import styles from "./RoutesList.module.scss";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import { RouteListItem } from "./RoutesListItem";
import { useSelectedDateHasPassed } from "@/Utils/useSelectedDateHasPassed";
import NoResults from "@/components/NoResults/NoResults";
import * as Sentry from "@sentry/react";
import type { IVisit } from "@models/visits";

type IRoutesList = {
  status: IRouteStatus;
};

const getVisitsCoordinates = (visits: IVisit[]) => {
  return visits.map(
    ({
      patient: {
        address: { coordinates },
      },
    }) => ({
      longitude: coordinates.longitude,
      latitude: coordinates.latitude,
    }),
  );
};

export const RoutesList = ({ status }: IRoutesList) => {
  const selectedDate = useSelectedDate();
  const routesAreEditable =
    !useSelectedDateHasPassed() && status !== "finished";
  const {
    data: routes = [],
    isPending,
    isError,
    error,
  } = useRoutes({ date: selectedDate, status });

  const routesCoordinates = routes.map(({ visits }) =>
    getVisitsCoordinates(visits),
  );
  const { data: directions = [] } = useDirections(routesCoordinates);

  if (isPending) {
    return <Loading message="Hämtar rutter" padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Gick inte att hämta rutterna. ${deducedError(error)}`}
        padding={0}
      />
    );
  }

  return routes.length === 0 ? (
    <NoResults message="Inga rutter att visa." padding={24} />
  ) : (
    <article>
      <ul className={styles.routesList}>
        {routes.map((route, routeIndex) => {
          const directionsForRoute = directions[routeIndex];
          const legDurationsForRoute = directionsForRoute
            ? directionsForRoute.legDurations
            : null;
          return (
            <RouteListItem
              key={route.id}
              legDurations={legDurationsForRoute}
              route={route}
              isEditable={routesAreEditable}
            />
          );
        })}
      </ul>
    </article>
  );
};
