import { useFormContext } from "react-hook-form";
import RadioButton from "@/components/RadioButton/RadioButton";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import type { ICreatePatientNoteFormData } from "./CreatePatientNoteForm";
import { patientNoteTypes, typeDictionary } from "@models/patientNotes";

export const PatientNoteTypePicker = () => {
  const { register } = useFormContext<ICreatePatientNoteFormData>();

  return (
    <RadioButtons orientation="horizontal" legend="Anteckningstyp">
      {patientNoteTypes.map((type) => (
        <RadioButton
          key={type}
          label={{ text: typeDictionary[type].sv }}
          visualStyle="framed"
          value={type}
          {...register("anonymisedNote.type")}
        />
      ))}
    </RadioButtons>
  );
};
