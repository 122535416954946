import type { SortingState } from "@tanstack/react-table";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { IPatientsTableItem } from "./PatientsTable";
import { PatientsTable } from "./PatientsTable";
import type { IListProspectPatient } from "@/api/Patients";
import { useProspectPatients } from "@/api/Patients";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import NoResults from "@/components/NoResults/NoResults";
import { useMemo, useState } from "react";
import NotificationsCell from "./NotificationsCell";
import NameCell from "./NameCell";
import { useFeatureFlag } from "@/api/FeatureFlags";
import * as Sentry from "@sentry/react";
import { PATIENT_TABLE_COLUMN_DISPLAY_NAMES } from "./patientsTableUtils";

type IProspectPatientsItem = IPatientsTableItem<IListProspectPatient>;

const COLUMN_DISPLAY_NAMES = {
  ...PATIENT_TABLE_COLUMN_DISPLAY_NAMES,
};

const columnHelper = createColumnHelper<IProspectPatientsItem>();

const getDataFromPatients = (patients: IListProspectPatient[]) => {
  return patients.map((patient) => {
    return {
      patient: patient,
      ward: patient.ward,
      notifications: patient.id,
      registeredAt: patient.registeredAt,
    };
  });
};

const columns = [
  columnHelper.display({
    id: "notifications",
    cell: ({ row: { original } }) => {
      const patientId = original.patient.id;
      return <NotificationsCell patientId={patientId} />;
    },
  }),
  columnHelper.accessor("patient", {
    header: COLUMN_DISPLAY_NAMES["patient"],
    cell: ({ getValue }) => {
      const patient = getValue();
      return <NameCell patient={patient} />;
    },
    sortingFn: (a, b) => {
      const aPatientName = a
        .getValue<IListProspectPatient>("patient")
        .name.toLowerCase();
      const bPatientName = b
        .getValue<IListProspectPatient>("patient")
        .name.toLowerCase();

      if (aPatientName < bPatientName) {
        return -1;
      } else if (aPatientName > bPatientName) {
        return 1;
      }
      return 0;
    },
  }),
  columnHelper.accessor("ward", {
    header: COLUMN_DISPLAY_NAMES["ward"],
    cell: ({ getValue }) => {
      const ward = getValue();
      return ward.displayName;
    },
  }),
];

export const ProspectPatients = () => {
  const { data: patients, isError, isPending, error } = useProspectPatients({});
  const { data: cardiologyEnabled } = useFeatureFlag("CardiologyWorkflow");

  const data = useMemo(() => getDataFromPatients(patients || []), [patients]);
  const featuredColumns = useMemo(() => {
    const columnsWithoutWard = columns.slice(0, 2);
    return cardiologyEnabled ? columns : columnsWithoutWard;
  }, [cardiologyEnabled]);

  const [sorting] = useState<SortingState>([{ id: "patient", desc: false }]);

  const table = useReactTable({
    data,
    columns: featuredColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  if (isPending) {
    return <Loading message="Hämtar patienter under bedömning" padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Gick inte att hämta patienter under bedömning. ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  if (patients.length === 0) {
    return (
      <NoResults
        message={`Det finns inga patienter under bedömning här.`}
        padding={24}
      />
    );
  }

  return <PatientsTable<IProspectPatientsItem> table={table} />;
};
