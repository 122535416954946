import Chip from "./Chip";
import type { IPatientNoteStatus } from "@models/patientNotes";

type INoteStatusChip = {
  status: IPatientNoteStatus;
  children?: string;
};

export const NoteStatusChip = (props: INoteStatusChip) => {
  const { status, children } = props;

  return status === "accepted" ? (
    <Chip border="solid" color="green">
      {`Godkänd av ${children}`}
    </Chip>
  ) : (
    <Chip border="solid" color="yellow">
      {`Ej sparad`}
    </Chip>
  );
};
