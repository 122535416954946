import type {
  IActivityCategory,
  IActivityOccurrence,
  IActivityOccurrenceStatus,
  IHomeVisitActivityOccurrenceWithoutPatientAndAssignees,
} from "@models/activities";
import {
  activityOccurrenceStatusSchema,
  getActivityOccurrenceStatusTimestamp,
  timeOfDayDictionary,
} from "@models/activities";
import { DetailsPopover } from "../DetailsPopover/DetailsPopover";
import styles from "./TimelineTile.module.scss";
import clsx from "clsx";
import { ActivityDetails, Details } from "./Details";
import type { IVisit, IVisitStatus } from "@models/visits";
import {
  calculateVisitTimes,
  getVisitStatusTimestamp,
  visitStatusSchema,
} from "@models/visits";
import { ActivityGroupTag } from "./ActivityGroupTag/ActivityGroupTag";
import { getInitials } from "@components/CircledInitials/initialsUtils";
import type { IStatusTag } from "@/components/StatusTag/StatusTag";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { useLocation } from "react-router-dom";
import type { IStatus } from "@/components/StatusTag/statusTagUtils";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import type { IAllocatableItemType } from "../Shifts";

type IVariant = "default" | "compact";
type AllocatableItemTypeWithoutUndefined = Exclude<
  IAllocatableItemType,
  undefined
>;

type ITile = {
  category: IActivityCategory;
  variant: IVariant;
  status: IStatus;
  statusTimestamp?: Date;
  patientName?: string;
  activityCount?: number;
  isAnyTimeOfDay?: boolean;
  timespan?: { start: Date; end: Date };
  showIndicator?: boolean;
  activities?:
    | IActivityOccurrence[]
    | IHomeVisitActivityOccurrenceWithoutPatientAndAssignees[];
};

const Tile = ({
  category,
  variant,
  status,
  statusTimestamp,
  patientName,
  activityCount,
  isAnyTimeOfDay,
  timespan,
  showIndicator,
  activities,
}: ITile) => {
  const DisplayedStatusAndTime = ({ size }: Pick<IStatusTag, "size">) => {
    const displayTime = isAnyTimeOfDay
      ? timeOfDayDictionary.Any.short
      : timespan
        ? formattedTimeSpan(timespan.start, timespan.end)
        : "-";

    const statusesWithoutTimestamp = [
      activityOccurrenceStatusSchema.Values.notReady,
      activityOccurrenceStatusSchema.Values.ready,
      visitStatusSchema.Values.planned,
    ];
    const displayStatusWithTimestamp =
      statusTimestamp &&
      !statusesWithoutTimestamp.find((item) => item === status);

    return (
      <div className={styles.statusAndTime}>
        {displayStatusWithTimestamp ? (
          <StatusTag
            status={status}
            timestamp={statusTimestamp}
            variant="icon-with-timestamp"
            size={size}
          />
        ) : (
          <div className={styles.time}>{displayTime}</div>
        )}
        {showIndicator && <NotificationCircle isVisible />}
      </div>
    );
  };

  const queryParams = useLocation().search;

  if (variant === "compact") {
    return (
      <>
        <ActivityGroupTag
          category={category}
          patient={patientName ? getInitials(patientName) : undefined}
          count={activityCount}
        />
        <DisplayedStatusAndTime size="tiny" />
      </>
    );
  }

  return (
    <>
      <ActivityGroupTag
        category={category}
        patient={patientName ? patientName : undefined}
      />
      {activities && activities.length > 0 ? (
        <ul>
          {activities.map((activity) => (
            <li key={activity.id}>
              <ActivityTitle
                activityOccurrence={activity}
                linkTo={`activities/${activity.activityId}/occurrences/${activity.id}${queryParams}`}
                weight="regular"
                showStatus={activity.status === "expired"}
              />
            </li>
          ))}
        </ul>
      ) : null}
      <DisplayedStatusAndTime size="small" />
    </>
  );
};

const tileClassName = (
  status: IActivityOccurrenceStatus | IVisitStatus,
  variant: IVariant,
  itemType: AllocatableItemTypeWithoutUndefined,
) => {
  return clsx(
    styles[`variant-${variant}`],
    status === activityOccurrenceStatusSchema.Values.finished && styles.faded,
    styles[itemType],
  );
};

export const TimelineVisitTile = ({
  visit,
  variant,
  routeId,
}: {
  visit: IVisit;
  variant: IVariant;
  routeId: string;
}) => {
  const { status, occurrences, patient } = visit;
  const statusTimestamp = getVisitStatusTimestamp(visit);
  const { start, end, isAnyTimeOfDay } = calculateVisitTimes(visit);
  const visitHasWellDefinedTime = start && end;
  const category = "HomeVisit";
  const visitHasExpiredActivities = occurrences.some(
    (activity) => activity.status === "expired",
  );

  if (variant === "default") {
    return (
      <article className={tileClassName(status, variant, "group")}>
        <Tile
          category={category}
          variant={variant}
          status={status}
          statusTimestamp={statusTimestamp}
          patientName={patient.name}
          activityCount={occurrences.length}
          isAnyTimeOfDay={isAnyTimeOfDay}
          timespan={visitHasWellDefinedTime ? { start, end } : undefined}
          showIndicator={visitHasExpiredActivities}
          activities={occurrences}
        />
      </article>
    );
  }

  return (
    <DetailsPopover
      trigger={
        <button className={tileClassName(status, variant, "group")}>
          <Tile
            category={category}
            variant={variant}
            status={status}
            statusTimestamp={statusTimestamp}
            patientName={patient.name}
            activityCount={occurrences.length}
            isAnyTimeOfDay={isAnyTimeOfDay}
            timespan={visitHasWellDefinedTime ? { start, end } : undefined}
            showIndicator={visitHasExpiredActivities}
            activities={occurrences}
          />
        </button>
      }
      triggerProps={{ asChild: true }}
      contentProps={{ side: "right" }}
    >
      <Details visit={visit} routeId={routeId} />
    </DetailsPopover>
  );
};

export const TimelineActivityTile = ({
  activityOccurrence,
  variant,
}: {
  activityOccurrence: IActivityOccurrence;
  variant: IVariant;
}) => {
  const { category, start, end, status, patient, timeOfDay } =
    activityOccurrence;
  const isAnyTimeOfDay = timeOfDay === "Any";
  const statusTimestamp =
    getActivityOccurrenceStatusTimestamp(activityOccurrence);

  if (variant === "default") {
    return (
      <article className={tileClassName(status, variant, "activityOccurrence")}>
        <Tile
          category={category}
          variant={variant}
          status={status}
          statusTimestamp={statusTimestamp}
          patientName={patient ? patient.name : undefined}
          isAnyTimeOfDay={isAnyTimeOfDay}
          timespan={{ start, end }}
          activities={[activityOccurrence]}
        />
      </article>
    );
  }

  return (
    <DetailsPopover
      trigger={
        <button
          className={tileClassName(status, variant, "activityOccurrence")}
        >
          <Tile
            category={category}
            variant={variant}
            status={status}
            statusTimestamp={statusTimestamp}
            patientName={patient ? patient.name : undefined}
            isAnyTimeOfDay={isAnyTimeOfDay}
            timespan={{ start, end }}
            activities={[activityOccurrence]}
          />
        </button>
      }
      triggerProps={{ asChild: true }}
      contentProps={{ side: "right" }}
    >
      <ActivityDetails activityOccurrence={activityOccurrence} />
    </DetailsPopover>
  );
};
