import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Form from "@/components/Form/Form";
import { activityKeys, addActivity, ANY_TIME_OF_DAY } from "@/api/Activities";
import type {
  INewActivity,
  INewHomeVisitActivity,
  INewPatientTaskActivity,
  INewVideoActivity,
  INewAdminTaskActivity,
  INewPatientMeasurementTaskActivity,
} from "@/api/Activities";
import type { ICompetencePickerFields } from "./CompetencePicker";
import { CompetencePicker } from "./CompetencePicker";
import { generateRandomUUID } from "@/Utils/UniqueId";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import { FilledButton, PlainButton } from "@components/Button/Button";
import type {
  IVideoType,
  IActivityCategory,
  IMeasurementsType,
} from "@models/activities";
import {
  videoTypeSchema,
  measurementSchema,
  timeOfDayDictionary,
} from "@models/activities";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import { ActivityTypePicker } from "./ActivityTypePicker";
import { PatientSelect } from "./PatientSelect";
import type { ITitleInputFields } from "./TitleInput";
import { TitleInput } from "./TitleInput";
import { DescriptionInput } from "./DescriptionInput";
import type { ISchedulingFields } from "./Scheduling/Scheduling";
import { Scheduling } from "./Scheduling/Scheduling";
import { recurringDictionary } from "./Scheduling/recurrenceUtils";
import { useEffect } from "react";
import { useSelectedDateNoDefault } from "@/Utils/useSelectedDate";
import { MeasurementsPicker } from "@/forms/AddActivityForm/MeasurementsPicker";
import { titleSuggestions } from "./titleSuggestions";
import type { ITemplate } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/templates";
import { templates } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/templates";
import { useNavigateWithPreservedQueryParams } from "@/Utils/useNavigateWithPreservedQueryParams";
import styles from "./AddActivityForm.module.scss";
import { getTimeFields, getTimespan } from "./activityTimeUtils";

export type IAddActivityFormData = ITitleInputFields &
  ISchedulingFields &
  ICompetencePickerFields & {
    category: IActivityCategory;
    description: string;
    doubleStaffing: boolean;
    duration: number;
    hidden: boolean;
    patientId: string;
    type: IVideoType;
    measurements: IMeasurementsType[];
  };

export const AddActivityForm = () => {
  const { navigateWithPreservedQueryParams } =
    useNavigateWithPreservedQueryParams();
  const selectedDate = useSelectedDateNoDefault();
  const { patientId: patientIdFromParams } = useParams();
  const methods = useForm<IAddActivityFormData>({
    defaultValues: {
      hidden: false,
      patientId: "", // This ensures that the disabled "Välj patient" option is the default, rather than the first patient in the list.
      recurrence: recurringDictionary.never,
      recurrencesPerDay: "1",
      startDate: selectedDate,
      timeCategory: timeOfDayDictionary.Specific.long,
      measurements: [
        measurementSchema.Values.bloodPressure,
        measurementSchema.Values.pulse,
        measurementSchema.Values.saturation,
        measurementSchema.Values.temperature,
      ],
    },
  });

  const {
    formState: { errors, isDirty },
    register,
    reset,
    resetField,
    getValues,
    handleSubmit,
    setError,
    watch,
    setValue,
  } = methods;

  const queryClient = useQueryClient();
  const { mutate, isPending: isAdding } = useMutation({
    mutationFn: ({ newActivity }: { newActivity: INewActivity }) =>
      addActivity(newActivity),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: activityKeys.all });
      navigateWithPreservedQueryParams("..");
    },
  });

  const validateAndSubmit = handleSubmit((validatedFormData) => {
    const {
      patientId: patientIdFromForm,
      category,
      description,
      doubleStaffing,
      recurrence,
      requiredCompetences,
      startDate,
      endDate,
      timeCategory,
      timeSensitivity,
      timeslots,
      title,
      weekdays,
      hidden,
      measurements,
      duration,
    } = validatedFormData;

    const isAnyTimeOfDay = timeCategory === timeOfDayDictionary.Any.long;
    const isRecurring = recurrence !== recurringDictionary.never;
    const isRecurringAsInterval =
      recurrence !== recurringDictionary.custom &&
      recurrence !== recurringDictionary.never;

    const commonActivityFields: Pick<
      INewActivity,
      | "activityId"
      | "category"
      | "description"
      | "duration"
      | "hidden"
      | "startDate"
      | "timespan"
      | "title"
    > = {
      activityId: generateRandomUUID(),
      category,
      description: description.trim(),
      duration: duration ?? 15,
      hidden,
      startDate,
      timespan: getTimespan({
        isAnyTimeOfDay,
        timeSensitivity,
      }),
      title: title.trim(),
    };

    const patientId = patientIdFromParams ?? patientIdFromForm;

    const timeFields = isRecurring
      ? {
          ...getTimeFields({
            isRecurringAsInterval,
            isAnyTimeOfDay,
            timeslots,
            weekdays,
            recurrence,
          }),
          endDate: endDate === "" ? undefined : endDate,
        }
      : {
          time: isAnyTimeOfDay
            ? ANY_TIME_OF_DAY
            : // @ts-expect-error we know that there is exactly one timeslot, but TS doesn't.
              timeslots[0].time,
        }; // We only have one timeslot if it's not recurring.

    if (category === "VideoCall") {
      const newActivity: INewVideoActivity = {
        ...commonActivityFields,
        patientId,
        category: "VideoCall",
        requiredCompetences,
        ...timeFields,
        type: videoTypeSchema.Values.DigitalVisit,
      };

      mutate({ newActivity });
    }

    if (category === "HomeVisit") {
      const newActivity: INewHomeVisitActivity = {
        ...commonActivityFields,
        patientId,
        category: "HomeVisit",
        requiredCompetences,
        ...timeFields,
        doubleStaffing,
      };

      mutate({ newActivity });
    }

    if (category === "PatientTask") {
      const newActivity: INewPatientTaskActivity = {
        ...commonActivityFields,
        patientId,
        category: "PatientTask",
        ...timeFields,
      };

      mutate({ newActivity });
    }

    if (category === "AdminTask") {
      const newActivity: INewAdminTaskActivity = {
        ...commonActivityFields,
        patientId: patientId ? patientId : null, // Represent lack of patient id as null.
        category: "AdminTask",
        requiredCompetences: requiredCompetences ? requiredCompetences : [],
        ...timeFields,
      };

      mutate({ newActivity });
    }

    if (category === "PatientMeasurementTask") {
      const newActivity: INewPatientMeasurementTaskActivity = {
        ...commonActivityFields,
        patientId,
        category: "PatientMeasurementTask",
        measurements: measurements,
        ...timeFields,
      };

      mutate({ newActivity });
    }
  });

  const currentCategory = watch(`category`);
  const currentPatientId = watch(`patientId`);
  const activityHasPatient =
    currentPatientId !== "" || patientIdFromParams !== undefined;
  const currentCategoryAllowsCompetence =
    currentCategory === "HomeVisit" ||
    currentCategory === "VideoCall" ||
    currentCategory === "AdminTask";
  const currentCategoryRequiresCompetence =
    currentCategory === "HomeVisit" || currentCategory === "VideoCall";
  const currentCategoryRequiresPatient = currentCategory !== "AdminTask";
  const activityIsHideable =
    (currentCategory === "HomeVisit" ||
      currentCategory === "VideoCall" ||
      currentCategory === "PatientTask" ||
      currentCategory === "PatientMeasurementTask") &&
    activityHasPatient;
  const currentCategoryMayRequireDoubleStaffing =
    currentCategory === "HomeVisit";
  const currentCategoryRequireMeasurements =
    currentCategory === "PatientMeasurementTask";

  useEffect(() => {
    if (!activityHasPatient) {
      resetField("hidden");
    }
  }, [activityHasPatient, resetField]);

  // We expect to get undefined if the title is not in the template list.
  const currentTemplate: Omit<ITemplate, "templateOptions"> =
    // @ts-expect-error - "title" is not always of ITitleSuggestion type, even though 'template.get' wants it to be.
    templates[getValues("title")];
  const titleHasTemplate = currentTemplate !== undefined;

  const populateFromTemplate = () => {
    if (!currentTemplate) {
      return;
    }

    Object.entries(currentTemplate).forEach(([key, value]) => {
      const field = key as keyof IAddActivityFormData;
      setValue(field, value);
    });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={validateAndSubmit}>
        {isAdding ? (
          <Loading message="Lägger till aktiviteten" />
        ) : (
          <>
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage message={errors.root.server.message} />
            ) : undefined}

            {/* ACTIVITY TYPE */}
            <Form.Section>
              <ActivityTypePicker
                categoryConditionals={{
                  currentCategoryRequiresCompetence,
                  currentCategoryMayRequireDoubleStaffing,
                }}
              />
            </Form.Section>

            {/* MEASUREMENTS */}
            {currentCategoryRequireMeasurements ? (
              <Form.Section>
                <MeasurementsPicker />
              </Form.Section>
            ) : null}

            {/* PATIENT, TITLE, DESCRIPTION */}
            <Form.Section width="half">
              {!patientIdFromParams ? (
                <PatientSelect required={currentCategoryRequiresPatient} />
              ) : (
                <></>
              )}
              <div>
                <TitleInput
                  suggestions={titleSuggestions[currentCategory ?? "Templates"]}
                />
                {titleHasTemplate ? (
                  <div className={styles.actionButtonBelowInputField}>
                    <PlainButton size="small" onClick={populateFromTemplate}>
                      Fyll i från mall
                    </PlainButton>
                  </div>
                ) : null}
              </div>
              <DescriptionInput />
            </Form.Section>

            {/* SCHEDULING */}
            <Form.Section>
              <Scheduling />
            </Form.Section>

            {/* COMPETENCE */}
            {currentCategoryAllowsCompetence ? (
              <Form.Section>
                <CompetencePicker
                  isRequired={currentCategoryRequiresCompetence}
                />
              </Form.Section>
            ) : null}

            {/* OTHER */}
            {currentCategoryMayRequireDoubleStaffing || activityIsHideable ? (
              <Form.Section>
                <Checkboxes legend="Övrigt" orientation="horizontal">
                  {currentCategoryMayRequireDoubleStaffing ? (
                    <Checkbox
                      errorMessage={errors.doubleStaffing?.message}
                      label={{ text: "Kräver dubbelbemanning" }}
                      visualStyle="framed"
                      {...register("doubleStaffing")}
                    />
                  ) : (
                    <></>
                  )}
                  {activityIsHideable ? (
                    <Checkbox
                      errorMessage={errors.hidden?.message}
                      label={{ text: "Dölj aktiviteten för patienten" }}
                      visualStyle="framed"
                      {...register("hidden")}
                    />
                  ) : (
                    <></>
                  )}
                </Checkboxes>
              </Form.Section>
            ) : null}

            {/* SUBMIT BUTTON */}
            <Form.SubmitButtonWrapper>
              <FilledButton type="submit">Spara aktiviteten</FilledButton>
            </Form.SubmitButtonWrapper>
          </>
        )}
      </Form>
    </FormProvider>
  );
};
