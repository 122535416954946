import { PATIENT_API_URL } from "@/Utils/EnvUtils";
import type { IPostMessageData } from "@components/Iframe/Iframe";
import { Iframe } from "@components/Iframe/Iframe";
import { useNavigate } from "react-router-dom";
import { prospectPatientSchema } from "@models/patients";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";

export const RegisterProspectPatient = () => {
  const navigate = useNavigate();
  const centerBaseUrl = useCenterBaseUrl();

  const postMessageHandler = {
    messageType: "patientRegistered",
    onMessageReceived: (data: IPostMessageData) => {
      try {
        const { id: patientId } = prospectPatientSchema
          .pick({ id: true })
          .parse({ id: data.patientId });
        navigate(`${centerBaseUrl}/patients/${patientId}`);
      } catch (_error) {
        navigate(`${centerBaseUrl}/patients/prospect`);
      }
    },
  };

  return (
    <Iframe
      title="Registrera patient för utvärdering"
      src={`${PATIENT_API_URL}/htmx/center/patients/add`}
      loadingMessage="Laddar formulär"
      loadingPadding={24}
      postMessageHandler={postMessageHandler}
    />
  );
};
