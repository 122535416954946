import { LoadingOverlay } from "@components/Loading/Loading";
import styles from "./AllocateToShiftTile.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import {
  activityOccurrenceAndGroupKeys,
  allocateShiftsToActivityOccurrence,
} from "@/api/Activities";
import { DropZone } from "@/components/DragAndDrop/DropZone";
import type { IAllocatableItemType } from "../Shifts";
import PlusIcon from "@components/icons/PlusIcon";
import { assignGroupToShift, shiftKeys } from "@/api/Shifts";
import * as Sentry from "@sentry/react";
import { shiftName, type IScheduledShift } from "@models/shifts";
import clsx from "clsx";

export const AllocateToShiftTile = ({
  currentAllocatableItemType,
  shift,
  onDropSucceeded,
  onPendingAddToShift,
  children,
}: {
  currentAllocatableItemType: IAllocatableItemType;
  shift: IScheduledShift;
  onDropSucceeded: () => void;
  onPendingAddToShift: (onPending: boolean) => void;
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();
  const { id: shiftId } = shift;
  const name = shiftName({ ...shift, options: { length: "long" } });

  const { mutate: placeActivityOccurrenceInBlock, isPending: isAllocating } =
    useMutation({
      mutationFn: ({
        activityId,
        occurrenceId,
      }: {
        activityId: string;
        occurrenceId: string;
      }) =>
        allocateShiftsToActivityOccurrence([shiftId], activityId, occurrenceId),
      onError: (err) => {
        displayErrorMessageAlert(
          `Gick inte att lägga till aktiviteten i arbetspasset. ${deducedError(err)}`,
        );
      },
      onMutate: () => {
        onPendingAddToShift(true);
      },
      onSuccess: async () => {
        onDropSucceeded();
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: shiftKeys.lists(),
          }),
        ]);
      },
      onSettled: () => {
        onPendingAddToShift(false);
      },
    });

  const { mutate: placeGroupInBlock, isPending: isPlacingGroupInBlock } =
    useMutation({
      mutationFn: ({ groupId }: { groupId: string }) =>
        assignGroupToShift(groupId, shiftId),
      onError: (err) => {
        displayErrorMessageAlert(
          `Gick inte att lägga till besöket i rutten. ${deducedError(err)}`,
        );
      },
      onMutate: () => {
        onPendingAddToShift(true);
      },
      onSuccess: async () => {
        onDropSucceeded();
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: shiftKeys.lists(),
          }),
        ]);
      },
      onSettled: () => {
        onPendingAddToShift(false);
      },
    });

  const handleGroupOnDrop = (droppedText: string) => {
    placeGroupInBlock({
      groupId: droppedText,
    });
  };

  const handleActivityOccurrenceOnDrop = (droppedText: string) => {
    const [activityId, occurrenceId] = droppedText.split(":");

    if (!activityId || !occurrenceId) {
      Sentry.captureException(
        new Error(
          "A dropped item is missing at least one of the mandatory 'activityId' or 'occurrenceId'.",
        ),
      );
      displayErrorMessageAlert(
        "Gick inte att lägga till aktiviteten i arbetspasset.",
      );
      return;
    }

    placeActivityOccurrenceInBlock({
      activityId: activityId,
      occurrenceId: occurrenceId,
    });
  };

  const isAllocatableItemSelected = currentAllocatableItemType !== undefined;

  if (currentAllocatableItemType === "group") {
    return (
      <LoadingOverlay show={isPlacingGroupInBlock}>
        <DropZone
          ariaLabel={`Skapa rutt för ${name}`}
          getDroppedText={handleGroupOnDrop}
          className={clsx(
            styles.dropZone,
            isAllocatableItemSelected ? styles.selected : undefined,
          )}
          isDisabled={!isAllocatableItemSelected}
        >
          {children}
          {isAllocatableItemSelected && (
            <article className={styles.allocateToShiftContainer}>
              <PlusIcon />
              Ny rutt
            </article>
          )}
        </DropZone>
      </LoadingOverlay>
    );
  } else {
    return (
      <LoadingOverlay show={isAllocating}>
        <DropZone
          ariaLabel={`Lägg till aktivitet för ${name}`}
          getDroppedText={handleActivityOccurrenceOnDrop}
          className={clsx(
            styles.dropZone,
            isAllocatableItemSelected ? styles.selected : undefined,
          )}
          isDisabled={!isAllocatableItemSelected}
        >
          {children}
          {isAllocatableItemSelected && (
            <article className={styles.allocateToShiftContainer}>
              <PlusIcon />
              Lägg till
            </article>
          )}
        </DropZone>
      </LoadingOverlay>
    );
  }
};
