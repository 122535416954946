import type { IActivityCategory } from "@models/activities";
import { templateSuggestions } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/templates";
import { z } from "zod";

const homeVisitSuggestions = [
  "Aktivitetsregim",
  "Andningsbedömning",
  "Andningsgymnastik",
  "Andningsregim",
  "Asctiesdränage",
  "Basal omvårdnad",
  "Bladderscan",
  "Blodprov",
  "Blodtransfusion",
  "Byte av pump",
  "Byte larmklocka",
  "Byte materiallåda",
  "Byte PVK - perfier infart",
  "Byte Sensorem",
  "CVK - central infart",
  "Cirkulationsregim",
  "Dra infarter",
  "Dusch",
  "EKG",
  "Funktionsbedömning",
  "Förskrvining av hjälpmedel",
  "Fysisk undersökning",
  "Förflyttningsbedömmning",
  "Hämta hjälpmedel",
  "Holter-EKG/telemetri",
  "Hjälpmedelsbedömning",
  "Hjälpmedelsutprovning/utlämning",
  "Hämtning larmklocka",
  "Hämtning materiallåda",
  "Hämtning tekniklåda",
  "Infusion dropp",
  "Koppla pump",
  "Introbesök hos patient",
  "KAD - kateter",
  "Kapillär provtagning",
  "Kontroll infart",
  "Koppla bort pump",
  "Leverans larmklocka",
  "Leverans läkemedel",
  "Leverans mat",
  "Leverans materiallåda",
  "Läkemedel subkutan injektion",
  "Läkemedel intravenöst",
  "Medicin per oral",
  "Medicinsk apparatur i hemmet",
  "Midline",
  "Nyckelkvittens",
  "Omläggning central infart",
  "Omläggning dränage",
  "Omläggning perifer infart",
  "Patientenkät",
  "Picc-line",
  "Pleuradränage",
  "PVK - perifer infart",
  "Patienttransport",
  "Rehab bedömning",
  "Rekrytering av hjälpmedel",
  "Riskbedömning",
  "Råd/regim",
  "Samtal anhörig",
  "Subkutan injektion",
  "SVP - subkutan venport",
  "Sätta på/ta av stödstrumpor",
  "Sårodling",
  "Såromläggning",
  "Träning",
  "Träningsbedömning",
  "Träningsprogram",
  "Urintappning",
  "Urinodling",
  "Utprovning DVT strumpa",
  "Utskrivning",
  "Vätske- och urinmätning",
  "Återlämning nyckel",
  "Ätande och drickande",
  "Överlämning behandlingsmeddelande",
  "Överlämning läkemedelslista",
] as const;

const videoCallSuggestions = [
  "Anhörigsamtal",
  "Andningsbedömning",
  "Andningsgymnastik",
  "Andningsregim",
  "Aktivitetsregim",
  "Cirkulationsregim",
  "Funktionsbedömning",
  "Förskrvining av hjälpmedel",
  "Förflyttningsbedömmning",
  "Hjälpmedelsbedömning",
  "Hjälpmedelsöversyn",
  "Läkemedelsgenomgång",
  "Patientundervisning",
  "Pumpkontroll digital",
  "Rehab bedömning",
  "Rond",
  "Råd/regim",
  "Samtal anhörig",
  "Träning",
  "Träningsbedömning",
  "Träningsprogram",
  "Uppföljande samtal",
  "Övrig observation",
  "Översyn hemsituation",
] as const;

const patientTaskSuggestions = [
  "Självskattning symptom",
  "Ta medicin",
  "Utvärdering",
  "Vätske- och urinmätning",
] as const;

const adminTaskSuggestions = [
  "Anhörigsamtal",
  "Annan vårdgivare",
  "Behandling på sjukhus",
  "Bedömning vårdavd",
  "Besiktning",
  "Biltvätt",
  "Blanda medicin",
  "Dagsplanering",
  "Diagnostik på sjukhus",
  "Dosettförberedelse",
  "Däckbyte",
  "Fyll på materiallåda",
  "Hårdvara",
  "Information till patient",
  "Inskrivning",
  "Kontakt annan vårdgivare",
  "Kontrollera nya flaggningar",
  "Kontrollräkning av narkotika",
  "Lifecare",
  "Meddela provsvar",
  "Mjukvara",
  "Möte",
  "Norrsken",
  "Onsdagsmöte",
  "Parkering STG",
  "Påminnelse medicin",
  "Påminnesle möte/rond",
  "Påminnelse vitalparametrar",
  "Patientundervisning",
  "Remiss hemrehab",
  "Tanka",
  "Uppkoppling",
  "Uppföljande samtal",
  "Veckoavstämning",
  "Vårdmöte",
] as const;

const patientMeasurementTaskSuggestions = [
  "Egen mätning",
  "Egen mätning morgon",
  "Egen mätning kväll",
  "Egen mätning andningsfrekvens",
  "Egen mätning blodsocker",
  "Egen mätning puls",
  "Egen mätning syremättnad",
  "Egen mätning temperatur",
  "Egen mätning vikt",
] as const;

export const titleSuggestionSchema = z.enum([
  ...homeVisitSuggestions,
  ...videoCallSuggestions,
  ...patientTaskSuggestions,
  ...adminTaskSuggestions,
  ...patientMeasurementTaskSuggestions,
]);

export type ITitleSuggestion = z.infer<typeof titleSuggestionSchema>;

export const titleSuggestions: Record<
  IActivityCategory | "Templates",
  ITitleSuggestion[]
> = {
  Templates: [...templateSuggestions],
  HomeVisit: [...homeVisitSuggestions],
  VideoCall: [...videoCallSuggestions],
  PatientTask: [...patientTaskSuggestions],
  AdminTask: [...adminTaskSuggestions],
  PatientMeasurementTask: [...patientMeasurementTaskSuggestions],
};
