import {
  Root,
  Content,
  Group,
  Item,
  Arrow,
  Label,
  Separator,
  Trigger,
  DropdownMenuCheckboxItem as CheckboxItem,
} from "@radix-ui/react-dropdown-menu";
import { DropDownTriggerButton } from "@components/Button/Button";
import styles from "./DropdownMenu.module.scss";
import type { XOR } from "ts-xor";
import CaretDownIcon from "@components/icons/CaretDownIcon";
import type { IBaseButton } from "@components/Button/Button";
import { TextWithCheckOrCross } from "../TextWithCheckOrCross/TextWithCheckOrCross";

type ICustomTrigger = Pick<IBaseButton, "weight" | "size" | "disabled"> &
  XOR<
    { text: string },
    XOR<
      { icon: JSX.Element; ariaLabel: string },
      { custom: JSX.Element; ariaLabel?: string }
    >
  >;

const CustomTrigger = ({
  text,
  icon,
  custom,
  ariaLabel,
  weight = "light",
  size,
  disabled = false,
}: ICustomTrigger) => {
  if (custom !== undefined) {
    return (
      <Trigger className={styles.customTrigger} aria-label={ariaLabel}>
        {custom}
      </Trigger>
    );
  } else if (icon !== undefined) {
    return (
      <DropDownTriggerButton
        contentType="icon"
        aria-label={ariaLabel}
        weight={weight}
        size={size}
        disabled={disabled}
      >
        {icon}
      </DropDownTriggerButton>
    );
    /* fallback <=> "text" */
  } else {
    return (
      <DropDownTriggerButton
        contentType="text-and-icon"
        weight={weight}
        size={size}
      >
        <CaretDownIcon />
        {text}
      </DropDownTriggerButton>
    );
  }
};

interface IDropdownMenu {
  trigger: ICustomTrigger;
  children: JSX.Element | (JSX.Element | undefined)[];
}

const DropdownMenu = ({ trigger, children }: IDropdownMenu) => {
  return (
    <Root>
      <CustomTrigger {...trigger} />
      <Content className={styles.content} collisionPadding={24}>
        {children}
        <Arrow className={styles.arrow} width={20} height={13} />
      </Content>
    </Root>
  );
};

const DropdownGroup = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return <Group className={styles.group}>{children}</Group>;
};

const DropdownItem = ({
  action,
  content,
  disabled,
}: {
  action?: () => void;
  content: string | JSX.Element;
  disabled?: boolean;
}) => {
  return (
    <Item
      onClick={!disabled ? action : () => null}
      className={styles.item}
      disabled={disabled}
    >
      {content}
    </Item>
  );
};

// Consider developing in this direction: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/switch_role
const DropdownToggleItem = ({
  toggledOn,
  disabled,
  label,
  onToggle,
}: {
  toggledOn: boolean | undefined;
  disabled?: boolean;
  onToggle: (checked: boolean) => void;
  label: string;
}) => {
  const labelWithStateIndicators =
    toggledOn === undefined ? (
      `${label} (okänd status)`
    ) : (
      <TextWithCheckOrCross
        text={`${label} (${toggledOn ? "på" : "av"})
  `}
        condition={toggledOn ?? false}
      />
    );

  return (
    <CheckboxItem
      checked={toggledOn}
      className={styles.item}
      disabled={disabled || toggledOn === undefined}
      onCheckedChange={onToggle}
      // Prevent the dropdown menu from closing when selecting that item.
      onSelect={(e) => e.preventDefault()}
    >
      {labelWithStateIndicators}
    </CheckboxItem>
  );
};

const DropdownLabel = ({ children }: { children: string }) => (
  <Label className={styles.label}>{children}</Label>
);

const DropdownSeparator = () => <Separator className={styles.separator} />;

DropdownMenu.Group = DropdownGroup;
DropdownMenu.Item = DropdownItem;
DropdownMenu.ToggleItem = DropdownToggleItem;
DropdownMenu.Label = DropdownLabel;
DropdownMenu.Separator = DropdownSeparator;

export default DropdownMenu;
