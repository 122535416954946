import type { IActivityOccurrenceOrGroup } from "@models/activities";
import { isGroup } from "@models/activities";
import styles from "./AllocatableItemTile.module.scss";
import { ActivityGroupTag } from "../TimelineTile/ActivityGroupTag/ActivityGroupTag";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { Text } from "@components/Text/Text";
import { TimeSpan } from "@/components/Time/TimeSpan";
import clsx from "clsx";

export const AllocatableItemTile = ({
  activityOccurrenceOrGroup,
  isSelected,
}: {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup;
  isSelected: boolean;
}) => {
  const { start, end, timeOfDay, category } = activityOccurrenceOrGroup;

  const requiredCompetences =
    "requiredCompetences" in activityOccurrenceOrGroup
      ? activityOccurrenceOrGroup.requiredCompetences
      : undefined;

  const occurrences = isGroup(activityOccurrenceOrGroup)
    ? activityOccurrenceOrGroup.occurrences
    : undefined;

  return (
    <div
      className={clsx(styles.tileContainer, isSelected ? styles.selected : "")}
    >
      <ActivityGroupTag
        category={category}
        patient={activityOccurrenceOrGroup.patient?.name}
        count={occurrences?.length}
      />
      <div className={styles.competencesAndTime}>
        {requiredCompetences && requiredCompetences.length > 0 ? (
          <ul className={styles.requiredCompetences}>
            {requiredCompetences.map((competence) => (
              <li key={competence}>
                <CompetenceChip
                  competence={competence}
                  state="neutral"
                  key={competence}
                />
              </li>
            ))}
          </ul>
        ) : null}
        <div className={styles.time}>
          <TimeSpan start={start} end={end} timeOfDay={timeOfDay} />
        </div>
      </div>
      {isSelected && occurrences && occurrences.length > 0 ? (
        <ul>
          {occurrences.map((occurrence) => (
            <li key={`${occurrence.id} ${occurrence.activityId}`}>
              <Text element="span" size="small" key={occurrence.id}>
                {occurrence.title}
              </Text>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};
