import { ActivitiesTable } from "./ActivitiesTable";
import styles from "./index.module.scss";
import { PlainButton } from "@components/Button/Button";
import { WorkBlocks } from "./WorkBlocks";
import PlusIcon from "@components/icons/PlusIcon";
import { DaySwitcher } from "@/components/DaySwitcher/DaySwitcher";
import { useSelectedDateNoDefault } from "@/Utils/useSelectedDate";
import { useNavigateWithPreservedQueryParams } from "@/Utils/useNavigateWithPreservedQueryParams";
import { useEffect } from "react";
import { format } from "@models/date-and-time";
import { useNavigate } from "react-router-dom";

const ActivitiesHeader = () => {
  const { navigateWithPreservedQueryParams } =
    useNavigateWithPreservedQueryParams();
  return (
    <header className={styles.activitiesHeader}>
      <DaySwitcher />
      <PlainButton onClick={() => navigateWithPreservedQueryParams(`new`)}>
        <PlusIcon />
        Ny aktivitet
      </PlainButton>
    </header>
  );
};

export const Activities = () => {
  const navigate = useNavigate();
  const selectedDate = useSelectedDateNoDefault();

  // Make sure we always have a date in the query params
  useEffect(() => {
    if (!selectedDate) {
      navigate(`?day=${format(new Date(), "yyyy-MM-dd")}`, { replace: true });
    }
  });

  return (
    <div className={styles.root}>
      <section className={styles.container}>
        <ActivitiesHeader />
        <ActivitiesTable />
      </section>
      <div className={styles.widgets}>
        <section className={styles.container}>
          <WorkBlocks />
        </section>
      </div>
    </div>
  );
};
