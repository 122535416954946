import type {
  IActivityOccurrencesAndGroups,
  ITimeOfDay,
} from "@models/activities";
import { isGroup } from "@models/activities";

export const getDataFromActivityOccurrencesAndGroups = (
  activityOccurrencesAndGroups: IActivityOccurrencesAndGroups,
) => {
  return activityOccurrencesAndGroups.map((activityOccurrenceOrGroup) => {
    if (isGroup(activityOccurrenceOrGroup)) {
      const group = activityOccurrenceOrGroup;
      return {
        activityOccurrenceOrGroup: group,
        category: "HomeVisit" as const,
        timespan: [
          group.start,
          group.end,
          group.occurrences.every(({ timeOfDay }) => timeOfDay === "Any")
            ? "Any"
            : "Specific",
        ] as [Date, Date, ITimeOfDay],
        title: group,
        patient: group.occurrences[0]?.patient,
        actors: group,
        block: group,
        status: group.occurrences.map(({ status }) => status),
      };
    } else {
      const activityOccurrence = activityOccurrenceOrGroup;
      return {
        activityOccurrenceOrGroup: activityOccurrence,
        category: activityOccurrence.category,
        timespan: [
          activityOccurrence.start,
          activityOccurrence.end,
          activityOccurrence.timeOfDay,
        ] as [Date, Date, ITimeOfDay],
        title: activityOccurrence,
        patient: activityOccurrence.patient,
        actors: activityOccurrence,
        block: activityOccurrence,
        status: [activityOccurrence.status],
      };
    }
  });
};
