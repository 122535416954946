import { DaySwitcher } from "@/components/DaySwitcher/DaySwitcher";
import styles from "./Shifts.module.scss";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useShiftsViewExpandedMode } from "./useShiftsViewExpandedMode";
import StandaloneCheckbox from "@/components/Checkbox/StandaloneCheckbox";
import { useFeatureFlag } from "@/api/FeatureFlags";
import clsx from "clsx";
import type { Selection } from "react-aria-components";
import { ShiftsSideBar } from "./SideBar/ShiftsSideBar";
import { ShiftsSection } from "./ShiftsSection";

export type IAllocatableItemType = "group" | "activityOccurrence" | undefined;

const Shifts = () => {
  const { state }: { state?: { day?: string } } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const [isExpanded, setIsExpanded] = useShiftsViewExpandedMode();
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set());
  const [isPendingAddToShift, setIsPendingAddToShift] = useState(false);
  const [currentlyAllocatableItemType, setCurrentlyAllocatableItemType] =
    useState<IAllocatableItemType>(undefined);

  const { data: allocateInShiftsView } = useFeatureFlag("AllocateInShiftsView");

  // If the state object has a day property, set the search params to that day
  // Passed from CommandCenterContainer.tsx
  useEffect(() => {
    if (state?.day) setSearchParams({ day: state?.day });
  }, [setSearchParams, state]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExpanded(e.target.checked);
  };

  const handleDropSucceeded = () => {
    setSelectedKeys(new Set());
    setCurrentlyAllocatableItemType(undefined);
  };

  return (
    <section className={styles.container}>
      <section className={styles.toolbar}>
        <DaySwitcher size="h2" />
        <StandaloneCheckbox
          label="Expanderat läge"
          name="expanded-view"
          checked={isExpanded}
          onChange={handleCheckboxChange}
        />
      </section>

      <div className={styles.contentWrapper}>
        <div
          className={clsx(
            styles.content,
            allocateInShiftsView && styles.allocateInShiftsViewFlagActive,
          )}
        >
          {allocateInShiftsView ? (
            <ShiftsSideBar
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              setCurrentlyAllocatableItemType={setCurrentlyAllocatableItemType}
              isPendingAddToShift={isPendingAddToShift}
            />
          ) : null}
          <div className={styles.shifts}>
            <ShiftsSection
              isExpanded={isExpanded}
              currentAllocatableItemType={currentlyAllocatableItemType}
              onDropSucceeded={handleDropSucceeded}
              onPendingAddToShift={setIsPendingAddToShift}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Shifts;
