import { deducedError } from "@/Utils/ErrorUtils";
import { getPatientNameWithStatus, usePatients } from "@/api/Patients";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import Select from "@/components/Select/Select";
import { useFormContext } from "react-hook-form";
import type { IAddActivityFormData } from "./AddActivityForm";
import { activeStatuses } from "@models/patients";
import * as Sentry from "@sentry/react";

export const PatientSelect = ({ required = false }: { required?: boolean }) => {
  const {
    data: patients,
    isError,
    isPending,
    error,
  } = usePatients({ statuses: activeStatuses });

  const {
    formState: { errors },
    register,
  } = useFormContext<IAddActivityFormData>();

  if (isPending) {
    return <Loading message="Hämtar patienter" />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Gick inte att hämta lista med patienter. ${deducedError(
          error,
        )}`}
      />
    );
  }

  return (
    <Select
      label={required ? "Patient" : "Patient (valfri)"}
      {...register(`patientId`, {
        required: {
          value: required,
          message: "Aktiviteten behöver en patient.",
        },
      })}
      errorMessage={errors.patientId?.message}
    >
      {required ? (
        <option value="" disabled hidden>
          Välj patient
        </option>
      ) : (
        <option value="">Ingen</option>
      )}
      <optgroup label="Patienter">
        {patients.map((patient) => (
          <option key={patient.id} value={patient.id}>
            {getPatientNameWithStatus(patient)}
          </option>
        ))}
      </optgroup>
    </Select>
  );
};
