import { JitsiMeeting } from "@jitsi/react-sdk";
import type { IJitsiMeetingProps } from "@jitsi/react-sdk/lib/types";

type IJitsiVideo = Omit<IJitsiMeetingProps, "domain"> & {
  videoServer: string;
};

const defaultIframeRef = (iframeRef: { style: { height: string } }) => {
  iframeRef.style.height = "100%";
};

export const JitsiVideo = ({
  getIFrameRef = defaultIframeRef,
  ...props
}: IJitsiVideo) => {
  return (
    <JitsiMeeting
      domain={props.videoServer}
      getIFrameRef={getIFrameRef}
      lang="sv" // Set UI language, overwriting built-in language detection: https://github.com/jitsi/jitsi-meet-react-sdk/issues/36
      // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe-events/#readytoclose
      onReadyToClose={() => {
        window.close();
      }}
      {...props}
    />
  );
};
