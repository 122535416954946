import type { ComponentProps } from "react";
import styles from "./DragAndDrop.module.scss";
import {
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
} from "react-aria-components";

export const ListBox = AriaListBox;
export const ListBoxItem = (
  // https://react-spectrum.adobe.com/react-aria/ListBox.html#listboxitem
  // A <ListBoxItem> defines a single option within a <ListBox>. If the children are not plain text, then the textValue prop must also be set to a plain text representation, which will be used for typeahead in the ListBox.
  props: ComponentProps<typeof AriaListBoxItem> & {
    textValue: Required<ComponentProps<typeof AriaListBoxItem>["textValue"]>;
  },
) => <AriaListBoxItem className={styles.option} {...props} />;
