import { measurementColor } from "@/Utils/ColorUtils";
import type { IChip } from "./Chip";
import Chip from "./Chip";
import type { IMeasurementsType } from "@models/activities";
import { measurementsDictionary } from "@models/activities";

const MeasurementChip = ({
  measurement,
  size,
}: {
  measurement: IMeasurementsType;
} & Pick<IChip, "size">) => {
  return (
    <Chip
      color={measurementColor[measurement].colorName}
      background={"solid"}
      border={"none"}
      size={size}
      shape={"sharp"}
    >
      {measurementsDictionary[measurement].sv}
    </Chip>
  );
};

export default MeasurementChip;
