import clsx from "clsx";
import styles from "./StatusTag.module.scss";
import CheckFilledIcon from "@components/icons/CheckFilledIcon";
import ArrowRightFilledIcon from "@components/icons/ArrowRightFilledIcon";
import { format } from "@models/date-and-time";
import type { IStatus } from "./statusTagUtils";
import { statusDictionary } from "./statusTagUtils";

export type IStatusTag = {
  status: IStatus;
  variant?:
    | "icon"
    | "icon-with-timestamp"
    | "icon-and-text"
    | "icon-and-text-with-timestamp";
  size?: "tiny" | "small" | "medium";
  timestamp?: Date;
};

export const StatusTag = ({
  status,
  variant = "icon",
  size = "small",
  timestamp,
}: IStatusTag) => {
  const label = statusDictionary[status].sv;

  const Icon = () => (
    <div className={styles.iconWrapper}>
      <div className={clsx(styles.icon, styles[`icon-${status}`])}>
        {status === "travellingTo" ? <ArrowRightFilledIcon /> : null}
        {status === "finished" ? <CheckFilledIcon /> : null}
      </div>
    </div>
  );

  if (variant === "icon") {
    return (
      <div
        className={clsx(
          styles.statusTag,
          styles[`variant-${variant}`],
          styles[`color-${status}`],
          styles[`size-${size}`],
        )}
      >
        <Icon />
      </div>
    );
  }

  if (variant === "icon-with-timestamp") {
    return (
      <div
        className={clsx(
          styles.statusTag,
          styles[`variant-${variant}`],
          styles[`color-${status}`],
          styles[`size-${size}`],
        )}
      >
        <Icon />
        {timestamp ? (
          <div className={styles.text}>{format(timestamp, "HH:mm")}</div>
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={clsx(
        styles.statusTag,
        styles[`variant-${variant}`],
        styles[`color-${status}`],
        styles[`size-${size}`],
      )}
    >
      <Icon />
      <div className={styles.text}>
        <span>{label}</span>
        {timestamp ? <span>{format(timestamp, "HH:mm")}</span> : null}
      </div>
    </div>
  );
};
