import { AllocatableItemTile } from "../AllocatableItem/AllocatableItemTile";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Heading } from "@components/Heading/Heading";
import NoResults from "@/components/NoResults/NoResults";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";
import { isGroup, type IActivityOccurrenceOrGroup } from "@models/activities";
import { useUnplannedOccurrencesAndGroups } from "@/api/Activities";
import {
  useDragAndDrop,
  type DraggableCollectionStartEvent,
  type Selection,
} from "react-aria-components";
import type { IAllocatableItemType } from "../Shifts";
import styles from "./ShiftsSideBar.module.scss";
import { ListBox, ListBoxItem } from "@/components/DragAndDrop/DragAndDrop";

export const ShiftsSideBar = ({
  selectedKeys,
  setSelectedKeys,
  setCurrentlyAllocatableItemType,
  isPendingAddToShift,
}: {
  selectedKeys: Selection;
  setSelectedKeys: (selectedKeys: Selection) => void;
  setCurrentlyAllocatableItemType: (type: IAllocatableItemType) => void;
  isPendingAddToShift: boolean;
}) => {
  const selectedDate = new Date(useSelectedDate());

  const {
    data: unplannedOccurrencesAndGroups,
    isPending,
    isError,
    error,
  } = useUnplannedOccurrencesAndGroups(
    selectedDate.toDateString(),
    selectedDate.toDateString(),
  );

  const onDragStart = (e: DraggableCollectionStartEvent) => {
    handleSelectionChange(e.keys);
  };
  const { dragAndDropHooks } = useDragAndDrop({
    getItems: (keys) => {
      return [...keys].map((key) => {
        const item = unplannedOccurrencesAndGroups?.find(
          (occurrenceOrGroup) => getUniqueId(occurrenceOrGroup) === key,
        );

        if (!item) {
          return {
            "medoma-item": "",
          };
        }

        return {
          "medoma-item": getUniqueId(item),
        };
      });
    },
    onDragStart,
  });

  if (isPending) {
    return (
      <section className={styles.toPlan}>
        <Loading message="Hämtar oplanerade aktiviteter" padding={24} />
      </section>
    );
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Gick inte att hämta oplanerade aktiviteter. ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  const handleSelectionChange = (selectedKeys: Selection) => {
    // TODO: Update to getting the id from selectionUtil when done
    const firstId =
      selectedKeys === "all"
        ? ""
        : selectedKeys &&
          selectedKeys.size > 0 &&
          selectedKeys.values().next().value;

    const firstItem = unplannedOccurrencesAndGroups.find(
      (item) => getUniqueId(item) === firstId,
    );

    const type = firstItem
      ? isGroup(firstItem)
        ? "group"
        : "activityOccurrence"
      : undefined;

    if (selectedKeys === "all" || selectedKeys.size > 0) {
      setSelectedKeys(selectedKeys);
      setCurrentlyAllocatableItemType(type);
    } else {
      setSelectedKeys(new Set());
      setCurrentlyAllocatableItemType(type);
    }
  };

  const getUniqueId = (occurrenceOrGroup: IActivityOccurrenceOrGroup) => {
    return isGroup(occurrenceOrGroup)
      ? occurrenceOrGroup.id
      : `${occurrenceOrGroup.activityId}:${occurrenceOrGroup.id}`;
  };
  const getDisabledKeys = (
    unplannedOccurrencesAndGroups: IActivityOccurrenceOrGroup[],
  ): Selection | undefined => {
    if (!isPendingAddToShift) return undefined;
    if (!unplannedOccurrencesAndGroups) return undefined;
    if (selectedKeys === "all") return undefined;

    return new Set(
      unplannedOccurrencesAndGroups
        .map((item) => getUniqueId(item))
        .filter((id) => !selectedKeys.has(id)),
    );
  };

  return (
    <section className={styles.toPlan}>
      <Heading className={styles.shiftsSideBarHeader} level="h2">
        Att planera
      </Heading>
      {unplannedOccurrencesAndGroups.length === 0 ? (
        <NoResults message="Inga aktiviteter att planera." padding={24} />
      ) : (
        <ListBox
          aria-label="Oplanerade aktiviteter"
          selectionMode="single"
          items={unplannedOccurrencesAndGroups.map(
            (unplannedOccurrenceOrGroup) => ({
              id: getUniqueId(unplannedOccurrenceOrGroup),
              name:
                "title" in unplannedOccurrenceOrGroup
                  ? unplannedOccurrenceOrGroup.title
                  : unplannedOccurrenceOrGroup.patient.name,
              unplannedOccurrenceOrGroup,
            }),
          )}
          onSelectionChange={handleSelectionChange}
          disabledKeys={getDisabledKeys(unplannedOccurrencesAndGroups)}
          dragAndDropHooks={dragAndDropHooks}
          className={styles.listBox}
        >
          {({ id, name, unplannedOccurrenceOrGroup }) => (
            <ListBoxItem textValue={name}>
              <AllocatableItemTile
                activityOccurrenceOrGroup={unplannedOccurrenceOrGroup}
                isSelected={
                  selectedKeys &&
                  (selectedKeys === "all" || selectedKeys.has(id))
                }
              />
            </ListBoxItem>
          )}
        </ListBox>
      )}
    </section>
  );
};
