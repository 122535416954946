import {
  dischargePatient,
  patientKeys,
  readmitPatient,
  useParentRoutesPatient,
} from "@/api/Patients";
import { FilledButton, OutlinedButton } from "@components/Button/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import Form from "@/components/Form/Form";
import { useForm } from "react-hook-form";
import Checkbox from "@/components/Checkbox/Checkbox";
import styles from "./Discharge.module.scss";
import type { IPatient } from "@models/patients";
import { canDischargePatient, patientStatusSchema } from "@models/patients";

const canReadmitPatient = ({ status }: Pick<IPatient, "status">) =>
  status === patientStatusSchema.Values.discharged;

const Discharge = () => {
  const patient = useParentRoutesPatient();
  const queryClient = useQueryClient();
  const { mutate: dischargePatientMutation, isPending: isDischargingPatient } =
    useMutation({
      mutationFn: ({ patientId }: { patientId: string }) =>
        dischargePatient(patientId),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att skriva ut patienten. ${deducedError(error)}`,
        );
      },
      onSuccess: (_, { patientId }) => {
        return queryClient.invalidateQueries({
          queryKey: patientKeys.detail(patientId),
        });
      },
    });

  const { mutate: readmitPatientMutation, isPending: isReadmittingPatient } =
    useMutation({
      mutationFn: ({ patientId }: { patientId: string }) =>
        readmitPatient(patientId),
      onError: (error) => {
        displayErrorMessageAlert(
          `Gick inte att ångra utskrivningen. ${deducedError(error)}`,
        );
      },
      onSuccess: (_, { patientId }) => {
        return queryClient.invalidateQueries({
          queryKey: patientKeys.detail(patientId),
        });
      },
    });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      cancelledScheduledActivities: false,
    },
  });

  if (canDischargePatient(patient)) {
    return (
      <div className={styles.dischargedWrapper}>
        <Form
          onSubmit={handleSubmit(() => {
            dischargePatientMutation({ patientId: patient.id });
          })}
        >
          <Checkbox
            errorMessage={errors.cancelledScheduledActivities?.message}
            label={{ text: "Patientens aktiviteter är avslutade" }}
            {...register("cancelledScheduledActivities", {
              required: {
                value: true,
                message: "Bekräfta att aktiviteterna har avslutats.",
              },
            })}
          />
          <FilledButton
            disabled={isDischargingPatient}
            type="submit"
          >{`Skriv ut ${patient.name}`}</FilledButton>
        </Form>
      </div>
    );
  }

  if (canReadmitPatient(patient)) {
    return (
      <div className={styles.dischargedWrapper}>
        <p>{`${patient.name} är utskriven.`}</p>
        <OutlinedButton
          disabled={isReadmittingPatient}
          onClick={() => readmitPatientMutation({ patientId: patient.id })}
        >{`Ångra utskrivning av ${patient.name}`}</OutlinedButton>
      </div>
    );
  }

  return (
    <p className={styles.dischargedWrapper}>
      Denna patient kan inte skrivas ut eller återinskrivas.
    </p>
  );
};

export { Discharge };
