import styles from "./ShiftsRow.module.scss";
import Portrait from "@components/Portrait/Portrait";
import { Text } from "@components/Text/Text";
import {
  TimelineActivityTile,
  TimelineVisitTile,
} from "./TimelineTile/TimelineTile";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { assignGroupToShift, type IShiftWithContents } from "@/api/Shifts";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { LoadingOverlay } from "@components/Loading/Loading";
import {
  medicalCompetenceDictionary,
  shiftName,
  shiftTimeDisplayValues,
} from "@models/shifts";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { AllocateToShiftTile } from "./TimelineTile/AllocateToShiftTile";
import { activityOccurrenceAndGroupKeys } from "@/api/Activities";
import { routeKeys, type IRouteStatus } from "@/api/Routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DropZone } from "@/components/DragAndDrop/DropZone";
import type { IAllocatableItemType } from "./Shifts";
import { useState } from "react";
import { shiftKeys } from "@/api/Shifts";
import clsx from "clsx";

export const ShiftRow = ({
  shiftWithContents: { shift, items },
  isExpanded,
  currentAllocatableItemType,
  onDropSucceeded,
  onPendingAddToShift,
}: {
  shiftWithContents: IShiftWithContents;
  isExpanded: boolean;
  currentAllocatableItemType: IAllocatableItemType;
  onDropSucceeded: () => void;
  onPendingAddToShift: (onPending: boolean) => void;
}) => {
  const queryClient = useQueryClient();
  const NO_ROUTE_ID = "";
  const [currentRouteId, setCurrentRouteId] = useState(NO_ROUTE_ID);

  const { mutate: placeGroupInBlock, isPending: isPlacingGroupInBlock } =
    useMutation({
      mutationFn: ({
        groupId,
        routeId,
      }: {
        groupId: string;
        routeId: string;
      }) => assignGroupToShift(groupId, shift.id, routeId),
      onError: (err) => {
        setCurrentRouteId(NO_ROUTE_ID);
        displayErrorMessageAlert(
          `Gick inte att lägga till besöket i rutten. ${deducedError(err)}`,
        );
      },
      onMutate: () => {
        onPendingAddToShift(true);
      },
      onSuccess: async () => {
        setCurrentRouteId(NO_ROUTE_ID);
        onDropSucceeded();

        await Promise.all([
          queryClient.invalidateQueries({ queryKey: routeKeys.all }),
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.all(),
          }),
          queryClient.invalidateQueries({
            queryKey: shiftKeys.lists(),
          }),
        ]);
      },
      onSettled: () => {
        onPendingAddToShift(false);
      },
    });

  const name = shiftName({ ...shift, options: { length: "long" } });
  const competence = shift.employee?.competence || shift.competence;
  const selectedDate = new Date(useSelectedDate());
  const { startDateTime, endDateTime } = shift;
  const {
    startedDayBefore,
    hasEndTimeAfterToday,
    formattedDayBefore,
    formattedDayToday,
  } = shiftTimeDisplayValues({
    selectedDate,
    startDateTime,
    endDateTime,
  });
  const displayShiftTime = `${
    startedDayBefore
      ? `Start ${formattedDayBefore}`
      : hasEndTimeAfterToday
        ? `Start ${formattedDayToday}`
        : ""
  } ${formattedTimeSpan(startDateTime, endDateTime)}`;

  const handleOnDrop = (droppedText: string, routeId: string) => {
    setCurrentRouteId(routeId);
    placeGroupInBlock({
      groupId: droppedText,
      routeId: routeId,
    });
  };

  const isDisabledDropZone = (status: IRouteStatus): boolean => {
    return status === "finished" || currentAllocatableItemType !== "group";
  };

  return (
    <li className={styles.shiftRow}>
      <div className={styles.shiftPerson}>
        <AllocateToShiftTile
          currentAllocatableItemType={currentAllocatableItemType}
          shift={shift}
          onDropSucceeded={onDropSucceeded}
          onPendingAddToShift={onPendingAddToShift}
        >
          <div className={styles.portraitAndShift}>
            <Portrait
              name={name}
              photoUrl={shift?.employee?.imageURL}
              size="medium"
            />
            <div className={styles.text}>
              <Text element="span" weight="medium">
                {name}
              </Text>
              <Text element="p" size="small">
                {displayShiftTime}
              </Text>
              <CompetenceChip
                competence={competence}
                state="neutral"
                content={medicalCompetenceDictionary[competence].sv.short}
              />
            </div>
          </div>
        </AllocateToShiftTile>
      </div>
      <div
        className={clsx(
          styles.routesAndActivities,
          styles[`variant-${isExpanded ? "default" : "compact"}`],
        )}
      >
        {items.length !== 0
          ? items.map((item) => {
              // Show routes
              if ("visits" in item) {
                const routeItem = item;
                const visits = routeItem.visits;
                const routeId = routeItem.id;

                return (
                  <LoadingOverlay
                    key={routeItem.id}
                    show={
                      isPlacingGroupInBlock && currentRouteId === routeItem.id
                    }
                  >
                    <DropZone
                      className={clsx(
                        styles.dropZone,
                        isDisabledDropZone(routeItem.status)
                          ? undefined
                          : styles.selectedGroup,
                      )}
                      ariaLabel={`Lägg till i rutt för ${name}`}
                      isDisabled={isDisabledDropZone(routeItem.status)}
                      getDroppedText={(droppedText: string) =>
                        handleOnDrop(droppedText, routeItem.id)
                      }
                    >
                      {visits.map((visitItem) => {
                        const visit = visitItem;
                        return (
                          <TimelineVisitTile
                            key={visit.id}
                            visit={visit}
                            routeId={routeId}
                            variant={isExpanded ? "default" : "compact"}
                          />
                        );
                      })}
                    </DropZone>
                  </LoadingOverlay>
                );

                // TODO: Deprecated, only used when there are no routes in the response.
              } else if ("occurrences" in item) {
                const visitItem = item;
                return (
                  <div key={visitItem.id} className={styles.dropZone}>
                    <TimelineVisitTile
                      visit={visitItem}
                      variant={isExpanded ? "default" : "compact"}
                      routeId={NO_ROUTE_ID}
                    />
                  </div>
                );

                // Show activity occurrences
              } else if ("activityId" in item) {
                const occurrenceItem = item;
                return (
                  <TimelineActivityTile
                    key={occurrenceItem.id}
                    activityOccurrence={occurrenceItem}
                    variant={isExpanded ? "default" : "compact"}
                  />
                );
              }
            })
          : null}
      </div>
    </li>
  );
};
