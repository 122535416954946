import styles from "./Patient.module.scss";
import { useContext, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import {
  declinePatient,
  getPatientNameWithStatus,
  patientsWithUnhandledMeasurementsQueryOptions,
  usePatient,
} from "@/api/Patients";
import { Tab, TabList } from "@components/Tabs";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { z } from "zod";
import { Heading } from "@components/Heading/Heading";
import CommunicationTab from "./CommunicationTab";
import { LOGISTICS_API_URL, NOTIFICATION_API_URL } from "@/Utils/EnvUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FilledButton, OutlinedButton } from "@components/Button/Button";
import type { IPatient } from "@models/patients";
import { PersonalIdentityNumber } from "@/components/PersonalIdentityNumber/PersonalIdentityNumber";
import { Text } from "@components/Text/Text";
import * as Sentry from "@sentry/react";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import { useFeatureFlag } from "@/api/FeatureFlags";
import ChatContext from "@chat/ChatContext";

type PatientEvent = MessageEvent<{ type: string; patientId: string }>;

const AdmitOrCancel = ({ patient }: { patient: IPatient }) => {
  const navigate = useNavigate();
  const { mutate: declinePatientMutation } = useMutation({
    mutationFn: ({ patientId }: { patientId: string }) =>
      declinePatient(patientId),
    onError: (error) => {
      displayErrorMessageAlert(
        `Gick inte att avslå ${patient.name}. ${deducedError(error)}`,
      );
    },
    onSuccess: () => {
      alert(`${patient.name} avslogs.`);
      navigate(`..`);
    },
  });

  return (
    <div className={styles.buttonGroup}>
      <OutlinedButton
        onClick={() => {
          if (
            window.confirm(
              `Är du säker på att du vill ge ${patient.name} avslag?\n\nOBS!\n\n1. Säkerställ först att det inte finns några olästa chattnotifikationer från patienten.`,
            )
          ) {
            declinePatientMutation({ patientId: patient.id });
          }
        }}
      >
        Avslå
      </OutlinedButton>
      <FilledButton onClick={() => navigate("admit")}>
        Fullfölj inskrivning
      </FilledButton>
    </div>
  );
};

const Patient = () => {
  const { chatEnabled } = useContext(ChatContext);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const { data: patient, isError, isPending, error } = usePatient(patientId);
  const { data: patientsWithUnhandledMeasurements } = useQuery(
    patientsWithUnhandledMeasurementsQueryOptions,
  );
  const hasUnhandledMeasurements = patientsWithUnhandledMeasurements?.find(
    ({ id, unhandledMeasurementsCount }) =>
      id === patientId && unhandledMeasurementsCount > 0,
  )
    ? true
    : false;
  const { data: patientNoteIsEnabled } = useFeatureFlag("PatientNote");
  const { data: useOtpForPatientLogin } = useFeatureFlag(
    "UseOTPForLoginPatient",
  );
  useEffect(() => {
    const handleShowNotification = (event: PatientEvent) => {
      if (event.origin !== LOGISTICS_API_URL) {
        return;
      }
      if (event.data.type !== "patientTabletAckMissing") {
        return;
      }
      if (event.data.patientId !== patient?.id) {
        return;
      }
      new Notification(`Misslyckades att notifiera patient ${patient?.name}`, {
        body: `Det ringer inte hos ${patient?.name}. Pröva att ringa igen, eller kontakta patienten på annat sätt.`,
        requireInteraction: true,
      });
    };

    window.addEventListener("message", handleShowNotification);

    return () => {
      window.removeEventListener("message", handleShowNotification);
    };
  }, [patient?.name, patient?.id]);

  if (isPending) {
    return <Loading message="Laddar patientdetaljer" padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`Kunde inte hämta patientdetaljer. ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  return (
    <>
      {/* This iframe is used to setup an SSE subscription to notifications from the Backend. 
          We can then act on these events, for example, showing an error message when the patient-tablet 
          has not received a notification for 'video-call-started'. */}
      <iframe
        style={{ display: "none" }}
        title="Patient notifications"
        src={`${NOTIFICATION_API_URL}/htmx/patient/${patientId}/sse`}
      />

      <header className={styles.header}>
        <div className={styles.topRow}>
          <Heading level="h1">{getPatientNameWithStatus(patient)}</Heading>
          <Text element="div" size="large">
            <PersonalIdentityNumber
              personalIdentityNumber={patient.personalIdentityNumber}
            />
          </Text>
          {patient.status === "prospect" ? (
            <AdmitOrCancel patient={patient} />
          ) : (
            <></>
          )}
        </div>
        <TabList>
          <Tab to="activities">Aktiviteter</Tab>
          <Tab to="measurements">
            <NotificationCircle
              isVisible={hasUnhandledMeasurements}
              placement="right"
            >
              Mätvärden
            </NotificationCircle>
          </Tab>
          {chatEnabled ? <CommunicationTab patientId={patient.id} /> : <></>}
          <Tab to="information">Information</Tab>
          {useOtpForPatientLogin ? <Tab to="equipment">Utrustning</Tab> : <></>}
          {patientNoteIsEnabled ? (
            <Tab to="patient-note">Patientanteckning</Tab>
          ) : (
            <></>
          )}
          <Tab to="discharge">Utskrivning</Tab>
        </TabList>
      </header>

      <div className={styles.content}>
        <Outlet context={patient} />
      </div>
    </>
  );
};

export default Patient;
